import { Controller } from '@hotwired/stimulus';

// Sample:
//  <button class='btn btn-sm' data-controller="print-panel" type='button'
//    data-print-target='#my-panel'>Print</button>
export default class extends Controller {
  declare element: HTMLElement;

  initialize() {
    this.element.addEventListener('click', this.printContent.bind(this), false);
  }

  printContent(e) {
    e.preventDefault();
    const panel = document.body.querySelector(this.element.getAttribute('data-print-target'));
    const mywindow = window.open('', '', 'width=1000, height=800');
    mywindow.document.write(`
      <html>${document.querySelector('head').outerHTML}
        <body class="p-5">
          ${panel.outerHTML}
          <script>document.body.querySelectorAll('.d-print-none').forEach((e) => e.remove())</script>
          <div class="text-center"><button class="d-print-none" id="printButton" onclick="window.print()">Imprimir</button></div>
        </body>
      </html>`);
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    setTimeout(() => {
      mywindow.print();
    }, 2);
  }
}
