import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { value: Number }
  declare valueValue: number;
  declare element: HTMLDivElement;

	initialize() {
		this.element.innerHTML = '';
    [5, 4, 3, 2, 1].forEach(i => this.addStar(i <= this.valueValue));
  }

  addStar(checked) {
    const tpl = `<span class="fa fa-star ${checked ? 'text-info' : ''}" />`;
    this.element.insertAdjacentHTML('afterbegin', tpl);
    this.element.classList.add('text-nowrap');
    this.element.classList.add('small');
  }
}
