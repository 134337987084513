import { Controller } from '@hotwired/stimulus';

// Sample:
// = li{ data-controller: 'row-deletable' }
//    = f_file.check_box :_destroy, 'data-row-deletable-target': 'check'
//    button{ data-row-deletable-target: 'btn' }
export default class extends Controller {
  declare element: HTMLElement;
  static targets = ['btn', 'check'];
  declare btnTarget: HTMLButtonElement;
  declare checkTarget: HTMLInputElement;
  declare hasCheckTarget: boolean;

  initialize() {
    if (this.hasCheckTarget) this.checkTarget.classList.add('d-none');
    this.btnTarget.addEventListener('click', this.deleteRow.bind(this));
  }

  deleteRow(e) {
    e.preventDefault();
    this.element.classList.add('d-none');
    if (this.hasCheckTarget) {
      this.checkTarget.checked = true;
      return;
    }
    this.element.remove();
  }
}
