import { Controller } from '@hotwired/stimulus';
import { objectToQueryString } from "../../helpers/misc";

// Allows to load calculator results based on the selected options
// Sample: <div data-controller="form-lab-calculator-results">
export default class extends Controller {
  static targets = ['calculators', 'loadLink', 'table'];
  declare element: HTMLElement;
  declare calculatorsTarget: HTMLSelectElement;
  declare loadLinkTarget: HTMLElement;
  declare tableTarget: HTMLElement;
  declare origUrl: string;

  initialize() {
    this.origUrl = this.loadLinkTarget.href;
    setTimeout(() => {
      this.calculatorsTarget.nextElementSibling.addEventListener('hidden.bs.dropdown', this.triggerLoad.bind(this));
    }, 1);
  }

  triggerLoad() {
    const values = {};
    Array.from(this.tableTarget.querySelectorAll('input[type="number"]'))
      .map((input) => values[input.getAttribute('data-id')] = input.value);
    const ids = Array.from(this.calculatorsTarget.querySelectorAll('option:checked'))
      .map((option) => option.value);
    this.loadLinkTarget.href = `${this.origUrl}&${objectToQueryString({ ids, values })}`;
    this.loadLinkTarget.click();
  }
}
