import { Controller } from '@hotwired/stimulus';
import { wrapEle } from '../../helpers/selectors';

// Allows password fields to hide/show value
//  = input_text_field :password, data-controller: 'toggle-password'
export default class extends Controller {
  declare element: HTMLInputElement;
  declare showPass: boolean;

  initialize() {
    this.showPass = false;
    if (!this.element.parentElement.classList.contains('input-group')) wrapEle(this.element, ['input-group']);
  }

  connect() {
    this.addIcon();
    this.updateIcon();
  }

  disconnect() {
    this.element.nextElementSibling.remove();
  }

  addIcon() {
    const tpl = '<span class="input-group-text pass-toggle"></span>';
    this.element.insertAdjacentHTML('afterend', tpl);
    this.element.nextElementSibling.addEventListener('click', () => {
      this.showPass = !this.showPass;
      if (this.showPass) this.element.type = 'text';
      else this.element.type = 'password';
      this.updateIcon();
      this.element.focus();
    });
  }

  updateIcon() {
    this.element.nextElementSibling.innerHTML = `<i class="fa ${this.showPass ? 'fa-eye-slash' : 'fa-eye'}"></i>`;
  }
}
