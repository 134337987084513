import { Controller } from '@hotwired/stimulus';

// Auto focus the mentioned tab without syncing with the URL (if not found, use the first one)
// Sample: <ul data-controller="focus-tab" data-focus-tab-key-value="info"> <li> <a class="nav-link" data-key="info"...
//   data-focus-tab-auto="true" will auto focus the first tab if the mentioned tab is not found
export default class extends Controller {
  static values = { key: String };
  declare element: HTMLCanvasElement;
  declare keyValue: String;

  initialize() {
    let tab = this.element.querySelector(`:scope > li > .nav-link[data-key="${this.keyValue}"]`);
    if (this.element.getAttribute('data-focus-tab-auto') === 'true' && !tab) {
      tab = this.element.querySelector(':scope > li > .nav-link');
    }
    tab?.click();
  }
}
