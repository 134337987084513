import { Controller } from '@hotwired/stimulus';

// Sample:
// .switcher-panel{ 'data-controller' => 'switch-panel' }
//    .current-panel{ 'data-switch-panel-target' => 'currentPanel' }
//      = link_to 'Edit feedback', '#', class: 'btn', 'data-switch-panel-target' => 'link'
//    .other-panel{ 'data-switch-panel-target' => 'otherPanel' } content for other panel1
//    .other-panel{ 'data-switch-panel-target' => 'otherPanel' } content for other panel2
export default class extends Controller {
  static targets = ['link', 'currentPanel', 'otherPanel']
  declare hasLinkTarget: boolean;
  declare linkTarget: HTMLLinkElement;
  declare currentPanelTargets: HTMLElement[];
  declare otherPanelTargets: HTMLElement[];

	initialize() {
    if (this.element.getAttribute('data-switch-panel-visible')) return;
    if (!this.hasLinkTarget) return;

    this.linkTarget.addEventListener('click', this.showSelector.bind(this), false);
    this.otherPanelTargets.forEach((item) => item.classList.add('d-none'));
  }

  showSelector(e) {
    e.preventDefault();
    this.currentPanelTargets.forEach((item) => {
      item.classList.add('d-none');
      item.querySelectorAll('input, select, textarea')
        .forEach((ele) => ele.setAttribute('disabled', 'true'));
    });
    this.otherPanelTargets.forEach((item) => item.classList.remove('d-none'));
  }
}
