import { Controller } from '@hotwired/stimulus';
import { concatUrlParams, debounce } from '../helpers/misc';
import { requestHeaders } from '../lib/turbo_request';

// Allows to show autocomplete results for text inputs
// Sample: <input data-controller="input-autocomplete" data-url="/asas.json">
export default class extends Controller {
  static values = { url: String, autosubmit: Boolean, dropdown: { type: Boolean, default: false } };
  declare urlValue: string;
  declare dropdownValue: boolean;
  declare element: HTMLInputElement;
  declare lastValue: string;
  declare autosubmitValue: boolean;
  declare dropdownPanel: HTMLElement;
  declare dropdown: HTMLElement;
  initialize() {
    this.lastValue = this.element.value;
    this.addDropdown();
    if (this.dropdownValue) {
      this.element.addEventListener('focus', () => {
        if (this.dropdown.querySelector('a')) this.dropdown.classList.add('show');
        else this.loadResults();
      }, false);
    } else {
      this.element.addEventListener('keyup', debounce(this.onChange.bind(this), 100), false);
      this.element.addEventListener('focus', () => {
        if (this.dropdown.querySelector('a')) this.dropdown.classList.add('show');
      }, false);
    }
  }

  onChange() {
    if (this.element.value.length < 3) this.dropdown.innerHTML = '';
    if (this.element.value.length < 3 || this.lastValue === this.element.value) return;
    this.lastValue = this.element.value;
    this.loadResults();
  }

  async loadResults() {
    const res = await fetch(concatUrlParams(this.urlValue, `q=${this.element.value}`))
      .then((response) => response.json());
    const options = res.map((item) => `<li><a class="dropdown-item" data-ping="${item.ping_url || ''}">${item.label}</a></li>`);
    this.dropdown.innerHTML = options.join('');
    this.bindOptionsEvent();
    if (options.length) this.dropdown.classList.add('show');
    else this.dropdown.classList.remove('show');
  }

  addDropdown() {
    const tpl = '<div class="dropend position-absolute start-0 top-100 w-100"><ul class="dropdown-menu w-100"></ul></div>';
    this.element.insertAdjacentHTML('afterend', tpl);
    this.dropdownPanel = this.element.nextElementSibling as HTMLElement;
    this.dropdown = this.dropdownPanel.querySelector('.dropdown-menu');
    this.element.addEventListener('blur', debounce(() => this.dropdown.classList.remove('show'), 150), false);
  }

  bindOptionsEvent() {
    this.dropdown.querySelectorAll('a').forEach((link) => {
      link.addEventListener('click', () => {
        this.element.value = link.innerText;
        const pingUrl = link.getAttribute('data-ping');
        if (pingUrl !== '') this.pingUsage(pingUrl);
        if (this.autosubmitValue) this.element.closest('form').querySelector('[type="submit"]').click();
      });
    });
  }

  pingUsage(url) {
    const reqData = {
      method: 'post',
      headers: { ...requestHeaders(), ...{} },
    };
    fetch(url, reqData);
  }
}
