import { Controller } from '@hotwired/stimulus';
import flatpickr from "flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js"

// Sample: <input data-controller="datepicker" 'data-datepicker-settings-value' => { minDate: 'today', mode: 'range' }.to_json />
export default class extends Controller {
  static values = { settings: { type: JSON, default: {} }, onlyTime: Boolean };
  declare settingsValue: object;
  declare onlyTimeValue: boolean;
  declare element: HTMLElement;
  declare wrapper: HTMLElement;

  // TODO: support for eng version
  initialize() {
    if (this.element.getAttribute('data-datepicker-parsed')) return;
    this.parseField();
    let def = { dateFormat: 'd-m-Y', locale: Spanish, wrap: true, allowInput: true, static: true };
    if (this.settingsValue.enableTime) def.dateFormat = 'd-m-Y H:i';
    if (this.onlyTimeValue) def = { ...def, ...{ dateFormat: 'H:i', enableTime: true, time_24hr: true, noCalendar: true } };
    const settings = { ...def, ...this.settingsValue };
    flatpickr(this.wrapper, settings);
    this.element.setAttribute('autocomplete', 'off');
  }

  parseField() {
    this.element.setAttribute('data-datepicker-parsed', 'true');
    this.buildWrapper();
    this.element.setAttribute('data-input', 'true');
    const tpl = `<i class="fa fa-times btn btn-sm position-absolute end-0 close-icon" data-clear></i>`;
    this.element.insertAdjacentHTML('afterend', tpl);
  }

  buildWrapper() {
    const wrapperClass = this.element.getAttribute('data-wrapper-class');
    this.wrapper = document.createElement('div');
    this.wrapper.setAttribute('class', `${wrapperClass} flatpickr flatpickr-wrapper`);
    this.element.parentNode.insertBefore(this.wrapper, this.element);
    this.wrapper.appendChild(this.element);
  }
}
