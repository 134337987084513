import { Controller } from '@hotwired/stimulus';

// Refreshes notifications frame everytime notifications was changed (only when opening)
export default class extends Controller {
  declare element: HTMLAnchorElement;
  declare changed: boolean;
  declare offCanvas: HTMLDivElement;
  declare badge: HTMLSpanElement;
  declare lastCounter: string;

  initialize() {
    const that = this;
    this.badge = this.element.querySelector('.badge');
    this.offCanvas = this.element.nextElementSibling as HTMLDivElement;
    this.badge.addEventListener('DOMNodeInserted', () => {
      that.changed = true;
      that.updateCounterStyle();
    });
    this.attachOffCanvasEvent();
    this.updateCounterStyle();
  }

  attachOffCanvasEvent() {
    const that = this;
    const frame = this.offCanvas.querySelector('turbo-frame');
    frame.addEventListener('show.bs.offcanvas', () => {
      if (that.changed) {
        frame.reload();
        that.changed = false;
      }
    });
  }

  updateCounterStyle() {
    if (this.lastCounter === this.badge.textContent) return;
    if (this.badge.textContent === '0') this.badge.classList.add('d-none');
    else this.badge.classList.remove('d-none');
    this.lastCounter = this.badge.textContent;
  }
}
