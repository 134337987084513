import { Controller } from '@hotwired/stimulus';
import { debounce, isInViewport } from '../helpers/misc';
import {ajaxRequest} from "../lib/turbo_request";

// Auto clicks the link when it comes into the viewport.
// Sample: <a data-controller="onviewport-trigger-link" href="/ping.json"
//  data-scroller-closest='.closest-panel'>Auto click on viewport</a>
export default class extends Controller {
  declare element: HTMLElement;
  declare scrollDebounced: () => void;
  declare scroller: Element|Window;

  initialize() {
    const closestSelector = this.element.getAttribute('data-scroller-closest');
    this.scroller = closestSelector ? this.element.closest(closestSelector) : window;
    this.scrollDebounced = debounce(this.checkViewport.bind(this), 50);
    this.scroller.addEventListener('scroll', this.scrollDebounced, false);
    this.checkViewport();
    this.element.addEventListener('click', (e) => e.preventDefault());
  }

  disconnect() {
    this.scroller.removeEventListener('scroll', this.scrollDebounced);
  }

  checkViewport() {
    if (!isInViewport(this.element, 0) || this.element.classList.contains('disabled')) return;

    this.disconnect();
    this.markSeen();
  }

  markSeen() {
    this.element.innerHTML = '<i class="fa fa-spinner fa-spin"></i>';
    ajaxRequest(this.element.getAttribute('href'), { loadingState: false }).then(() => {
      this.element.outerHTML = '<i class="fa fa-check-double"></i>';
    });
  }
}
