import { Controller } from '@hotwired/stimulus';
import {subscribeToEvent} from "../helpers/events";

type IWeekSlot = {
  string: string[]
}

// Sample: <div data-controller="cell-selector"><div class="free-slot"/></table>
export default class extends Controller {
  declare element: HTMLTableElement;
  declare selectableClass: string;
  declare selectedClass: string;
  declare startCell: HTMLLIElement;
  declare asSelected: boolean;
  declare startTimer: number;
  declare createButton: HTMLButtonElement;
  declare reservedTpl: string;
  declare bkSelectedAttr: string;

	initialize() {
    this.createButton = this.element.querySelector('#create-slots-btn');
    this.selectableClass = 'free-cell';
    this.selectedClass = 'selected-cell';
    this.bkSelectedAttr = 'data-was-selected';
    const cells = Array.from(this.element.querySelectorAll<HTMLElement>(`.${this.selectableClass}`));
    const allCells = Array.from(this.element.querySelectorAll<HTMLElement>('.cell-area'));
    subscribeToEvent({ element: cells, eventName: ['touchstart'], callback: this.onTouch.bind(this) });
    subscribeToEvent({ element: cells, eventName: ['mousedown'], callback: this.onDragStart.bind(this) });
    subscribeToEvent({ element: cells, eventName: ['mouseenter', 'touchmove'], callback: this.onDragMove.bind(this) });
    subscribeToEvent({ element: allCells, eventName: ['mouseup', 'touchend'], callback: this.onDragEnd.bind(this) });
    subscribeToEvent({ element: cells, eventName: ['contextmenu'], callback: (e) => { e.preventDefault(); e.stopImmediatePropagation(); return false; } });
    this.bindCreateForm();
    this.bindCheckEdit();
    this.listenDestroyChecks();
    this.addSelectAllColumn();
  }

  onTouch(e) {
    const target = e.currentTarget;
    this.startTimer = setTimeout(() => {
      this.startCell = target;
    }, 300);
  }

  onDragStart(e) {
    const target = e.currentTarget;
    e.preventDefault();
    this.startCell = target;
  }

  onDragMove(e) {
    if (this.startTimer) clearTimeout(this.startTimer);
    if (!this.startCell) return;
    const target = this.elementByPoint(e) || e.currentTarget;

    e.preventDefault();
    e.stopPropagation();
    this.updateCellsUntil(target);
  }

  onDragEnd(e) {
    if (this.startTimer) clearTimeout(this.startTimer);
    if (!this.startCell) return;

    const target = this.elementByPoint(e) || e.currentTarget;
    if (target === this.startCell) this.toggleCellSelectedStatus(target);

    this.startCell = null;
    this.updateSubmitButton();
    this.element.querySelectorAll<HTMLElement>('.cell-area')
      .forEach((item) => item.removeAttribute(this.bkSelectedAttr));
  }

  elementByPoint(e) {
    if (e.type.includes('touch')) {
      const evt = (typeof e.originalEvent === 'undefined') ? e : e.originalEvent;
      const touch = evt.touches[0] || evt.changedTouches[0];
      return document.elementFromPoint(touch.clientX, touch.clientY);
    }
    return null;
  }

  updateSubmitButton() {
    if (this.selectedCells().length) this.createButton.classList.remove('disabled');
    else this.createButton.classList.add('disabled');
  }

  isSelected(cell: HTMLLIElement) {
    return cell && cell.classList.contains(this.selectedClass);
  }

  toggleCellSelectedStatus(cell) {
    this.updateCellSelectedStatus(cell, !this.isSelected(cell));
  }

  updateCellSelectedStatus(cell: HTMLLIElement, asSelected = false) {
    if (!cell.classList.contains(this.selectableClass)) return;

    if (asSelected) {
      cell.classList.add(this.selectedClass);
    } else {
      cell.classList.remove(this.selectedClass);
    }
  }

  updateCellsUntil(endCell) {
    let indexRowFrom = Array.from(this.startCell.parentElement.children).indexOf(this.startCell);
    let indexRowEnd = [...endCell.parentElement.children].indexOf(endCell);
    [indexRowFrom, indexRowEnd] = this.fixIndexes(indexRowFrom, indexRowEnd);

    const colFrom = this.startCell.parentElement;
    const columns = Array.from(colFrom.parentElement.children);
    let indexFromCol = columns.indexOf(colFrom);
    const colTo = endCell.parentElement;
    let indexToCol = columns.indexOf(colTo);
    [indexFromCol, indexToCol] = this.fixIndexes(indexFromCol, indexToCol);

    for (let i = indexFromCol; i <= indexToCol; i += 1) {
      const rows = Array.from(columns[i].children);
      for (let j = indexRowFrom; j <= indexRowEnd; j += 1) {
        const cell = rows[j] as HTMLLIElement;
        const savedState = cell.getAttribute(this.bkSelectedAttr);
        if (!savedState) cell.setAttribute(this.bkSelectedAttr, this.isSelected(cell) ? 'false' : 'true');
        this.updateCellSelectedStatus(cell, cell.getAttribute(this.bkSelectedAttr) === 'true');
      }
    }
  }

  fixIndexes(from, to) {
    return (from > to) ? [to, from] : [from, to];
  }

  bindCreateForm() {
    this.createButton.addEventListener('click', () => {
      const input = this.element.querySelector<HTMLInputElement>('#create-slots-form [name="ranges"]');
      input.value = JSON.stringify(this.calculateRanges());
    }, false);
  }

  selectedCells() {
    return this.element.querySelectorAll(`.${this.selectedClass}`);
  }

  calculateRanges() :IWeekSlot{
    const dayColumns = [...this.element.querySelectorAll<HTMLUListElement>('.day-column')];
    const res = {} as IWeekSlot;
    dayColumns.forEach((column, index) => {
      const resDay = [];
      let group = [];
      column.querySelectorAll(`.${this.selectedClass}`).forEach(cell => {
        group.push(cell);
        if (!this.isSelected(cell.nextElementSibling as HTMLLIElement)) {
          resDay.push(group);
          group = [];
        }
      });
      res[column.getAttribute('data-date')] = this.toReducedRanges(resDay);
    });
    return res;
  }

  // @return Array<String>. Sample: ["10:00,13:30", "14:30,17:30"]
  toReducedRanges(gropedSelectedItems): string[] {
    return gropedSelectedItems.map(group => {
      const last = group[group.length - 1];
      return [group[0].getAttribute('data-time-from'), last.getAttribute('data-time-to')].join(',');
    });
  }

  // TODO: Refactor method complexity
  listenDestroyChecks() {
    let checked = [];
    let reserved = [];
    this.reservedTpl = this.element.querySelector('#form-delete-batch .with-reserved').innerHTML;
    this.element.querySelectorAll<HTMLInputElement>('[name="del_slot"]').forEach((check) => {
      check.addEventListener('change', () => {
        if (check.checked) {
          checked.push(check.value);
          if (check.closest('.cell-area').classList.contains('busy-cell')) reserved.push(check.value);
        } else {
          checked = checked.filter((v) => v !== check.value);
          reserved = reserved.filter((v) => v !== check.value);
        }
        this.updateManageBtns(checked, reserved);
      });
    });
    this.updateManageBtns(checked, reserved);
  }

  updateManageBtns(checked, reserved) { // TODO: remove .with-reserved as it no longer support to delete reserved
    const form = this.element.querySelector<HTMLFormElement>('#form-delete-batch');
    const editForm = this.element.querySelector<HTMLFormElement>('#edit-slots-form');
    const panelReserved = form.querySelector('.with-reserved');
    const button = this.element.querySelector('#batch-delete-link');
    const bkLabel = button.getAttribute('data-label');
    const editButton = this.element.querySelector('#edit-slots-btn');
    const editButtonLabel = editButton.getAttribute('data-label');

    form.querySelector<HTMLInputElement>('[name="ids"]').value = checked.join(',');
    editForm.querySelector<HTMLInputElement>('[name="ids"]').value = checked.join(',');
    button.innerHTML = checked.length ? `${bkLabel} (${checked.length})` : bkLabel;
    editButton.innerHTML = checked.length ? `${editButtonLabel} (${checked.length})` : editButtonLabel;
    if (checked.length) {
      button.classList.remove('disabled');
      editButton.classList.remove('disabled');
    } else {
      button.classList.add('disabled');
      editButton.classList.add('disabled');
    }
    if (reserved.length) panelReserved.innerHTML = this.reservedTpl;
    else panelReserved.innerHTML = '';
  }

  bindCheckEdit() {
    this.element.querySelectorAll('.appointment-slot').forEach((area) => {
      area.addEventListener('click', (e) => {
        if (e.target.name !== 'del_slot') area.querySelector('input[type="checkbox"]')?.click()
      });
    });
  }

  addSelectAllColumn() {
    const header = this.element.querySelector('.column-titles-bar');
    header.querySelectorAll('.day-title').forEach((day, index) => {
      const checkboxes = this.element.querySelectorAll<HTMLInputElement>(`.day-column:nth-child(${index + 1}) [name="del_slot"]`);
      if (index === 0) return;
      if (checkboxes.length === 0) return;

      day.insertAdjacentHTML('beforeend', '<input type="checkbox" class="select-all-day ms-2" />');
      const checkCol = day.querySelector<HTMLInputElement>('.select-all-day');
      checkCol.addEventListener('change', (e) => {
        checkboxes.forEach((check) => {
          check.checked = e.target.checked;
          check.dispatchEvent(new Event('change'));
        });
      });
    });
  }
}
