import { Controller } from '@hotwired/stimulus';
import I18n from '../../helpers/translations';

export default class extends Controller {
  declare element: HTMLFormElement;

  initialize() {
    this.bindOptions();
    this.updateOptions();
    this.bindDropdownAutoFilter();
    this.bindIasearch();
  }

  bindOptions() {
    this.moreOptions().forEach((a) => {
      a.addEventListener('click', (e) => {
        e.preventDefault();
        this.showInput(a);
        a.classList.add('d-none');
      });
    });
  }

  showInput(option) {
    const inputPanel = this.optionPanelFor(option);
    inputPanel.classList.remove('d-none');
    inputPanel.classList.add('fade_animation');
    const input = inputPanel.querySelector('select, .form-control') as HTMLInputElement;
    if (!input) return;

    input.click();
    input.focus();
  }

  updateOptions() {
    this.moreOptions().forEach((a) => {
      if (this.optionPanelFor(a) && !this.optionPanelFor(a).classList.contains('d-none')) a.classList.add('d-none');
    });
  }

  optionPanelFor(a) {
    return this.element.querySelector(`.filter-items > .${a.getAttribute('data-target')}`);
  }

  moreOptions() {
    return this.element.querySelectorAll('.more_options a[data-target]');
  }

  bindDropdownAutoFilter() {
    this.element.querySelectorAll('select').forEach((sel) => {
      if (!sel.getAttribute('data-no-autosubmit')) sel.addEventListener('change', () => this.element.submit());
    });
  }

  bindIasearch() {
    const reservedWords = I18n('ia_words').split(',');
    this.element.addEventListener('submit', (e) => {
      const field = this.element.querySelector<HTMLInputElement>('[name="q[text]"]');
      const search = field.value;
      const regex = new RegExp(reservedWords.join('|'), 'i');
      if (regex.test(` ${search} `)) {
        const link = this.element.querySelector<HTMLAnchorElement>('.btn-ai');
        link.href = `${link.href.split('?')[0]}?search=${search}`;
        link.click();
        e.preventDefault();
        field.value = '';
      }
    });
  }
}
