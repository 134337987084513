import '@hotwired/turbo-rails';
import './controllers/index.js';
import './lib/tab-auto-focus';
import './lib/turbo_request';
import 'sharer.js';
// import * as bootstrap from 'bootstrap';

Sharer.init();

// Log rocket
// import LogRocket from 'logrocket';
// LogRocket.init('doctobooking/doctobooking');
// LogRocket.identify(document.head.querySelector('[name="session-id"]').content);

// ****** Sentry settings
import * as Sentry from '@sentry/browser';
window.Sentry = Sentry;
Sentry.init({
  dsn: 'https://9821efc4cf074ce07da12543d1eb2557@o4505624196612096.ingest.sentry.io/4505624198250496',
  tunnel: '/sentry_proxy',
  // Performance Monitoring
  tracesSampleRate: 0.2, // Capture 20% of the transactions, reduce in production!
});
// ****** end

document.addEventListener("DOMContentLoaded", function(){
  if(window !== window.parent) { // iframe auto resize
    window.pymChild = new pym.Child();
    let lastH = 0;
    setInterval(() => {
      if (lastH !== document.body.clientHeight) {
        pymChild.sendHeight();
        lastH = document.body.clientHeight;
      }
    }, 2_000);
  }
});
