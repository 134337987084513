import { Controller } from '@hotwired/stimulus';
import { ajaxRequest } from '../lib/turbo_request';
import { concatUrlParams, isInViewport } from '../helpers/misc';

// Sample: <span data-controller="ajax-realtime"
//          data-ajax-realtime-time-value='10101010' />
//          data-ajax-realtime-url-value='/calendar/10' />
//  Url must return { time: Integer, content: String }
export default class extends Controller {
  static values = { url: String, time: Number, frame: String, delay: { type: Number, default: 5 } };

  declare element: HTMLElement;
  declare urlValue: string;
  declare timeValue: number;
  declare delayValue: number;
  declare frameValue: string;
  declare timer: number;
  declare activeTab: boolean;

  connect() {
    this.activeTab = true;
    this.startTimeout();
    document.addEventListener('turbo:submit-end', this.onResponse.bind(this), false);
    document.addEventListener('visibilitychange', this.checkTabStatus.bind(this), false);
  }

  disconnect() {
    if (this.timer) clearInterval(this.timer);
    document.removeEventListener('turbo:submit-end', this.onResponse.bind(this), false);
    document.removeEventListener('visibilitychange', this.checkTabStatus.bind(this), false);
  }

  checkTabStatus() {
    this.activeTab = document.visibilityState === 'visible';
    if (this.activeTab) this.doRequest();
  }

  // TODO: removeEventListener is not deleted on disconnect
  onResponse(e) {
    const method = e.target.method;
    if (this.element.isConnected && method === 'post') this.doRequest();
  }

  startTimeout() {
    const delay = window.env === 'development' ? this.delayValue * 20 : this.delayValue;
    this.timer = setInterval(this.doRequest.bind(this), delay * 1000);
  }

  doRequest() {
    if (!this.activeTab || !isInViewport(this.element)) return;

    const that = this;
    const url = concatUrlParams(this.urlValue, `time=${this.timeValue}&silence_ajax_error=true`);
    const headers = {};
    if (this.frameValue) headers['Turbo-Frame'] = this.frameValue;

    ajaxRequest(url, { loadingState: false, headers: headers })
      .then((response) => response.json())
      .then((res) => {
        that.timeValue = res.time;
        Turbo.renderStreamMessage(res.content);
      });
  }
}
