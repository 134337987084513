import { Controller } from '@hotwired/stimulus';

// Allows to delegate a click event to another element
// Sample: .div{ 'data-controller': 'click-delegator',
//               'data-click-delegator-selector-value': "[data-bs-target='#tab_doctors']" }
export default class ModalController extends Controller {
  static values = { selector: String };
  declare selectorValue: string;
  declare element: HTMLElement;

	initialize() {
    this.element.addEventListener('click', this.triggerClick.bind(this), false);
  }

  triggerClick(e) {
    e.preventDefault();
    let item = null;
    if (this.selectorValue === ':prev') item = this.element.previousElementSibling;
    else if (this.selectorValue === ':next') item = this.element.nextElementSibling;
    else item = document.querySelector<HTMLElement>(this.selectorValue);
    item?.click();
  }
}
