import { Controller } from '@hotwired/stimulus';
// TODO: should be done on the backend side (For Ruby performance reason was moved to frontend)
// Sample: <div class="calendar-grid" data-controller="cell-empty-rows-hide">
export default class extends Controller {
  declare element: HTMLElement;

  initialize() {
    const dayColumns = Array.from(this.element.querySelectorAll('ul.day-column, ul.hours-col'));
    const qtyRows = dayColumns[0].children.length;

    for (let i = 0; i < qtyRows; i += 1) {
      const cells = [];
      dayColumns.forEach((col) => {
        const cell = col.children[i];
        if (cell.classList.contains('free-cell') || cell.classList.contains('time-cell')) cells.push(cell);
      });
      if (dayColumns.length === cells.length) cells.forEach((cell) => cell.classList.add('d-none'));
    }
  }
}
