import { Controller } from '@hotwired/stimulus';

// Allows to keep synced input values with link href
// Sample: <input name='asas' /> <a data-controller="input-sync-link" href="/asas/?phone=XXX">
export default class extends Controller {
  declare element: HTMLElement;
  declare tpl:string;
  declare prevInput: HTMLInputElement;

  initialize() {
    this.prevInput = this.element.previousElementSibling as HTMLInputElement;
    this.tpl = this.element.getAttribute('href');
    this.element.addEventListener('click', this.updateHref.bind(this));
    this.bindChange();
  }

  bindChange() {
    const origValue = this.prevInput.value;
    this.prevInput.addEventListener('keyup', () => {
      if (origValue !== this.prevInput.value) {
        this.element.classList.remove('d-none');
      } else {
        this.element.classList.add('d-none');
      }
    });
  }

  updateHref() {
    this.element.setAttribute('href', this.tpl.replace('XXX', this.prevInput.value));
  }
}
