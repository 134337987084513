import { Controller } from '@hotwired/stimulus';
import I18n from '../helpers/translations';

// Sample:
//   <span data-controller="see-more" data-see-more-length-value="100">Large content</span>
export default class extends Controller {
  static values = { length: { type: Number, default: 200 } };

  declare element: HTMLElement;
  declare orgText: string;
  declare lengthValue: number;

  initialize() {
    this.orgText = this.element.innerHTML;
    if (this.orgText.length > this.lengthValue) this.showLink();
  }

  showLink() {
    this.element.innerHTML = `${this.orgText.slice(0, this.lengthValue)}... <a href="#" class="see-more-lnk">${I18n('see_more')}</a>`;
    this.element.querySelector('.see-more-lnk').addEventListener('click', this.showContent.bind(this), false);
  }

  showContent(e) {
    e.preventDefault();
    this.element.innerHTML = this.orgText;
  }
}
