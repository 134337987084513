import { Controller } from '@hotwired/stimulus';

// Sample: <form data-controller="assistant-login">
export default class extends Controller {
  declare element: HTMLElement;
  declare clinicDni: HTMLInputElement;

  initialize() {
    this.clinicDni = this.element.querySelector('.social-access [name="clinic_dni"]');
    this.clinicDni.addEventListener('keyup', this.updateLinks.bind(this));
    this.updateLinks();
  }

  updateLinks() {
    const value = this.clinicDni.value;
    this.element.querySelectorAll('.social-access form').forEach((form: HTMLFormElement) => {
      const url = new URL(form.action);
      url.searchParams.set('clinic_dni', value);
      form.action = url.toString();
      if (value) {
        form.querySelector('[type="submit"]').removeAttribute('disabled');
      } else {
        form.querySelector('[type="submit"]').setAttribute('disabled', 'disabled');
      }
    });
  }
}
