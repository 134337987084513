import { Controller } from '@hotwired/stimulus';

// Auto clear field value when pressed delete key
// Sample: <input data-controller="del-reset-field">
export default class extends Controller {
  declare element: HTMLInputElement;

  initialize() {
    this.element.addEventListener('keyup', this.resetField.bind(this), false);
  }

  resetField(e) {
    if (e.keyCode !== 8) return;

    this.element.value = '';
  }
}
