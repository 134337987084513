import { Controller } from '@hotwired/stimulus';
import I18n from "../../helpers/translations";

// = javascript_include_tag 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBNtjZOGOv8elv2KTbWKIsRlbPLqFGOoeo&libraries=places'
//   = text_field_tag :address, '', id: 'address'
export default class extends Controller {
  static targets = ['lat', 'lng', 'searchBox', 'address'];
  declare latTarget: HTMLInputElement;
  declare lngTarget: HTMLInputElement;
  declare searchBoxTarget: HTMLInputElement;
  declare addressTarget: HTMLInputElement;

  declare element: HTMLDivElement;
  declare map: any;
  declare autocomplete: any;
  declare marker: any;
  declare geocoder: any;
  declare addressAttr: string;

  initialize() {
    this.addressAttr = 'data-allow-complete';
    this.element.querySelector<HTMLElement>('.map')?.remove();
    this.checkAddressCompletion();
    this.initMap();
    this.buildAutoComplete();
    this.buildMarker();
    if (!this.latTarget.value) this.focusCurrentLocation();
  }

  checkAddressCompletion() {
    if (this.addressTarget.value === '') {
      this.addressTarget.setAttribute(this.addressAttr, 'true');
      this.addressTarget.addEventListener('keyup', () => this.addressTarget.removeAttribute(this.addressAttr));
    }
  }

  buildAutoComplete() {
    const options = {
      fields: ["geometry"],
      strictBounds: false,
      types: ["establishment"],
    };

    this.searchBoxTarget.style.cssText = 'position: absolute; z-index: 2; margin-top: 10px; max-width: 300px; width: 100%;';
    this.autocomplete = new google.maps.places.Autocomplete(this.searchBoxTarget, options);
    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete.getPlace();
      if (place.geometry) this.moveMarker(place.geometry.location);
    });
    this.autocomplete.bindTo('bounds', this.map);
    this.searchBoxTarget.addEventListener('keydown', (e) => {
      if (e.keyCode === 13) e.preventDefault();
    });
  }

  initMap() {
    this.element.insertAdjacentHTML('beforeend', '<div style="height: 400px; width: 100%" class="map"></div>');
    this.map = new google.maps.Map(
      this.element.querySelector<HTMLElement>('.map'),
      {
        center: { lat: -17.382861022536243, lng: -66.15466732124024 },
        zoom: 13,
        mapTypeControl: false,
        scrollwheel: false,
        styles: [{ featureType: 'poi.business', stylers: [{ visibility: 'off' }] }],
      },
    );
    this.geocoder = new google.maps.Geocoder();
  }

  buildMarker() {
    this.marker = new google.maps.Marker({
      map: this.map,
      anchorPoint: new google.maps.Point(0, -29),
      draggable: true,
      title: 'Drag to your consulting room',
    });
    google.maps.event.addListener(this.marker, 'dragend', () => {
      this.fillFields(this.marker.position);
    });
    google.maps.event.addListener(this.map, 'dragend', () => {
      this.moveMarker(this.map.getCenter());
    });

    if (this.lngTarget.value) {
      this.moveMarker({ lat: parseFloat(this.latTarget.value), lng: parseFloat(this.lngTarget.value) }, false);
    }
  }

  moveMarker(pos, updateFields = true) {
    this.map.setCenter(pos);
    this.marker.setPosition(pos);
    if (updateFields) this.fillFields(pos);
  }

  fillFields(position) {
    this.latTarget.value = typeof position.lat === 'function' ? position.lat() : position.lat;
    this.lngTarget.value = typeof position.lng === 'function' ? position.lng() : position.lng;
    this.calcStateInfo();
  }

  calcStateInfo() {
    this.geocoder.geocode({ latLng: this.marker.getPosition() }, (results, status) => {
      if (status !== google.maps.GeocoderStatus.OK) return;

      const address = results.find((a) => a.types.includes('locality')) || results.find((a) => a.types.includes('administrative_area_level_3')) || results.find((a) => a.types.includes('route'));
      const route = results.find((a) => a.types.includes('route'));
      if (this.element.querySelector('.city-field')) {
        const [city, province, state, country] = address.address_components.map((item) => item.long_name);
        this.element.querySelector('.city-field').value = city;
        this.element.querySelector('.province-field').value = province;
        this.element.querySelector('.state-field').value = state;
        this.element.querySelector('.country-field').value = country;
      }
      if (route && this.addressTarget.getAttribute(this.addressAttr)) {
        this.addressTarget.value = `${route.address_components[0].long_name} - ${route.address_components[1].long_name}`;
      }
    });
    // TODO: print error to sentry
  }

  showPermissionPanel() {
    const tpl = `<div class="alert alert-warning alert-sm mt-2">
      <i class="fa fa-warning"></i> 
      <a href="https://support.google.com/chrome/answer/142065?hl=es-419&co=GENIE.Platform%3DDesktop&oco=0" target="_blank">${I18n('geo_denied')}</a>
    </div>`;
    this.addressTarget.insertAdjacentHTML('afterend', tpl);
  }

  focusCurrentLocation() {
    const setDefaultPosition = () => {
      this.moveMarker({ lat: -17.382861022536243, lng: -66.15466732124024 });
    };
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          this.moveMarker({ lat: position.coords.latitude, lng: position.coords.longitude });
        },
        (e) => {
          console.error('not possible to detect your location:', e);
          this.showPermissionPanel();
          setDefaultPosition();
        },
      );
    } else {
      console.error('not possible to detect your location');
      setDefaultPosition();
    }
  }
}
