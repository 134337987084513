import { Controller } from '@hotwired/stimulus';

// Hides the element if the content is 0 (supports streaming)
// Sample: <div class="d-none" data-controller="hidden-if-zero">0</div>
export default class extends Controller {
  declare element: HTMLElement;

	initialize() {
    this.checkContent();
    this.element.addEventListener('DOMNodeInserted', this.checkContent.bind(this), false);
  }

  checkContent() {
    if (this.element.innerHTML === '0') this.element.classList.add('d-none');
    else this.element.classList.remove('d-none');
  }
}
