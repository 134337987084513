import { Controller } from '@hotwired/stimulus';

// Sample: <div data-controller="form-autofocus">
export default class extends Controller {
  declare element: HTMLInputElement;

  initialize() {
    this.element.querySelector('input:not([type="hidden"]), select, textarea')?.focus();
  }
}
