import { Controller } from '@hotwired/stimulus';
import I18n from '../../helpers/translations';

export default class extends Controller {
  declare element: HTMLDivElement;
  declare tpl: string;
  declare counter: number;
  initialize() {
    this.counter = 0;
    this.saveTpl();
    this.element.querySelector('a.add-row')?.addEventListener('click', this.addRow.bind(this));
    this.element.querySelector('a.import-btn')?.addEventListener('click', this.renderImport.bind(this));
    this.element.querySelector('a.show-sub-services')?.addEventListener('click', this.showTable.bind(this));
    this.bindDeleteRows(Array.from(this.element.querySelectorAll('tbody tr')));
  }

  saveTpl() {
    const lastRow = this.element.querySelector('tbody tr:last-child');
    this.tpl = lastRow.cloneNode(true).outerHTML;
    lastRow.remove();
  }

  showTable(e) {
    e.preventDefault();
    this.element.querySelector('.init_panel').classList.add('d-none');
    this.element.querySelector('.table_panel').classList.remove('d-none');
    this.addRow(null);
  }

  addRow(e, name:string = null) {
    if (e) e.preventDefault();
    this.counter -= 1;
    this.element.querySelector('tbody')?.insertAdjacentHTML('beforeend', this.tpl);

    const lastRow = this.element.querySelector('tbody tr:last-child');
    this.bindDeleteRows([lastRow]);
    lastRow.querySelectorAll('input, textarea, select').forEach((item, index) => {
      item.setAttribute('name', item.getAttribute('name').replace('[]', `[${this.counter}]`));
      if (index === 0) item.focus();
      if (item.getAttribute('name').includes('name')) item.value = name;
    });
  }

  renderImport(e) {
    e.preventDefault();
    const tpl = `<div class="row gx-0 import-panel">
            <div class="col-lg-auto">
              <button type="button" class="btn btn-danger cancel-import"><i class="fa fa-times"></i></button>
              <button type="button" class="btn btn-light import"><i class="fa fa-bolt"></i></button>
            </div>
            <div class="col-lg">
              <textarea class="form-control" rows="10" placeholder="${I18n('command_separated_msg')}"></textarea>
            </div>
          </div>`;
    e.target.insertAdjacentHTML('afterend', tpl);
    e.target.classList.add('d-none');

    const panel = e.target.nextElementSibling;
    panel.querySelector('textarea')?.focus();
    panel.querySelector('.import').addEventListener('click', () => {
      const data = panel.querySelector('textarea').value;
      data.split(/\n|,/).forEach((name) => this.addRow(null, name.trim()));
      panel.querySelector('.cancel-import').click();
    });
    panel.querySelector('.cancel-import').addEventListener('click', () => {
      panel.remove();
      e.target.classList.remove('d-none');
    });
  }

  bindDeleteRows(rows: HTMLElement[]) {
    rows.forEach((row) => {
      row.querySelector('.remove-row')?.addEventListener('click', (e) => {
        e.preventDefault();
        if (row.querySelector('.del-checkbox')) {
          row.classList.add('d-none');
          row.querySelector('.del-checkbox').checked = true;
        } else {
          row.remove();
        }
      });
    });
  }
}
