import { Controller } from '@hotwired/stimulus';
import I18n from '../../helpers/translations';
import { secondsToString } from '../../helpers/misc';

// Allows to control fields when creating appointment
export default class extends Controller {
  static values = { services: { type: Array, default: [] } };
  static targets = ['kindDropdown', 'fieldsPanel'];
  declare servicesValue: object[];
  declare kindDropdownTarget: HTMLSelectElement;
  declare hasKindDropdownTarget: boolean;
  declare fieldsPanelTarget: HTMLElement;
  declare element: HTMLElement;

  initialize() {
    if (this.hasKindDropdownTarget) {
      this.updateFields();
      this.kindDropdownTarget.addEventListener('change', this.updateFields.bind(this));
    }
  }
  updateFields() {
    let tpl = '';
    tpl += this.kindServicesDropdown();
    switch (this.kindDropdownTarget.value) {
      case 'onsite': {
        tpl += `
        <div class='map' data-controller='form-geo-picker'>
          <label for="appointment_onsite_address">Descripción de la ubicación <small> (*)</small></label>
          <textarea data-form-geo-picker-target="address" required="required" class="form-control mb-2" name="appointment[onsite_address]" id="appointment_onsite_address"></textarea>
          <input data-form-geo-picker-target="lat" required="required" class="visually-hidden" type="text" name="appointment[onsite_lat]" id="appointment_onsite_lat" />
          <input data-form-geo-picker-target="lng" class="visually-hidden" type="text" name="appointment[onsite_long]" id="appointment_onsite_long" />
          <input type="text" name="search_box" id="search_box" value="" data-form-geo-picker-target="searchBox" autocomplete="off" />
        </div>`;
        break;
      }
      case 'virtual': {
        break;
      }
      default: { // office
        break;
      }
    }
    this.fieldsPanelTarget.innerHTML = tpl;
    this.bindServiceDescr();
  }

  bindServiceDescr() {
    const field = this.fieldsPanelTarget.querySelector<HTMLSelectElement>('[name="appointment[appointment_service_id]"]');
    if (!field) return;

    field.addEventListener('change', () => {
      this.updateServiceInfo(field.value);
      this.updateSubServices(field.value);
    });
    field.dispatchEvent(new Event('change'));
  }

  updateServiceInfo(selected_svc_id) {
    const item = this.servicesValue.find((obj) => obj.id.toString() === selected_svc_id);
    const info = item?.descr ? `<div class="alert alert-info alert-sm bg-transparent d-flex">
            <i class="fa fa-info-circle fa-2x me-2"></i>
            <div>${item?.descr}</div></div>` : '';
    this.fieldsPanelTarget.querySelector('.service_descr').innerHTML = info;

    if (item.require_payment && item.price > 0) {
      this.element.querySelector('.payment_submit').classList.remove('d-none');
      this.element.querySelector('.normal_submit').classList.add('d-none');
    } else {
      this.element.querySelector('.normal_submit').classList.remove('d-none');
      this.element.querySelector('.payment_submit').classList.add('d-none');
    }
  }

  kindServicesDropdown() {
    const options = this.servicesValue.filter((item) => item.kind === this.kindDropdownTarget.value)
      .map((item) => `<option value="${item.id}">${item.name}</option>`);
    if (options.length === 0) return '';

    // Note: appointment_service_id autocomplete2 style does not work inside modals (scroll for many elements)
    return `
      <div class='mb-3'>
        <div class="row">
          <div class="col-md-6">
            <label for="appointment_appointment_service">Tipo de servicio <small> (*)</small></label>
            <select required="required" class="form-select" name="appointment[appointment_service_id]">
                ${options.join('')}
            </select>
          </div>
          <div class="col-md-6 sub_services_panel">
          </div>
        </div>
        <div class="service_descr"></div>
      </div>`;
  }

  updateSubServices(serviceId) {
    const item = this.servicesValue.find((obj) => obj.id.toString() === serviceId);
    let tpl = '';
    if (item.sub_services) {
      const options = item.sub_services.map((child) => `<option data-price="${child.price}" data-minutes="${child.minutes}" value="${child.id}">${child.name}</option>`);
      tpl = `
        <label>${I18n('sub_services')} (*)</label>
        <select name="appointment[sub_service_ids][]" data-controller="form-autocomplete2" multiple required>
          ${options.join('')}
        </select>`;
    }
    this.element.querySelector('.sub_services_panel').innerHTML = tpl;

    if (tpl) {
      this.element.querySelector('[name="appointment[sub_service_ids][]"]').addEventListener('change', (e) => {
        const selected = [...e.target.querySelectorAll('option:checked')]
          .map((opt) => item.sub_services.find((child) => child.id.toString() === opt.value));
        const price = item.price + selected.map((child) => child.price).reduce((a, b) => a + b, 0);
        const minutes = item.minutes + selected.map((child) => child.minutes).reduce((a, b) => a + b, 0);
        this.element.querySelector('.total_details').innerHTML = `<b>${I18n('total_cost')}</b>: $${price} - <b>${I18n('total_time')}:</b> ${secondsToString(minutes * 60)}`;
      });
    }
  }
}
