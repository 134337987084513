type ISubscribe = {
  element: HTMLElement|HTMLElement[],
  eventName: string|string[],
  callback: (e) => void
}
export const subscribeToEvent = ({ element, eventName, callback }: ISubscribe) => {
  let items = Array.isArray(element) ? element : [element];
  items = Array.isArray(element) ? element : [element];
  const eventNames = Array.isArray(eventName) ? eventName : [eventName];
  items.forEach((item) => {
    eventNames.forEach((ev) => item.addEventListener(ev, callback, false));
  });
};

export const emitEvent = (targetElement: HTMLElement, eventName, eventData = {}) => {
  const event = new CustomEvent(eventName, { detail: eventData });
  targetElement.dispatchEvent(event);
};
