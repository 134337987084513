import { Controller } from '@hotwired/stimulus';
import { Bilderrahmen } from 'bilderrahmen';

// Sample: <div data-controller="photo-gallery">
//  <a href='http..image.png'>
//    <img data-bilderrahmen="gallery-02" data-bilderrahmen-title="Video One"></...></div>
export default class extends Controller {
  declare element: HTMLAnchorElement;

  initialize() {
    new Bilderrahmen({ closeOnOutsideClick: true });
  }
}
