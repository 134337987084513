import { Controller } from '@hotwired/stimulus';
import { ajaxRequest } from '../lib/turbo_request';

export default class extends Controller {
  static values = { url: String };
  declare element: HTMLElement;
  declare timer: number;
  declare urlValue: string;
  disconnect() {
    this.stopTimer();
  }

  connect() {
    this.timer = setInterval(this.doRequest.bind(this), 2 * 1000);
  }

  stopTimer() {
    if (this.timer) clearInterval(this.timer);
  }

  doRequest() {
    const that = this;
    if (document.hidden) return;

    ajaxRequest(this.urlValue, { loadingState: false })
      .then((response) => response.json())
      .then((res) => {
        if (res.paid) {
          that.stopTimer();
          document.querySelector('#reload-payment-lnk')?.click();
        }
      });
  }
}
