import { Controller } from '@hotwired/stimulus';
import { requestHeaders } from '../lib/turbo_request';
import { concatUrlParams } from '../helpers/misc';
import I18n from '../helpers/translations';

// Submits the current form every 30 secs passing an extra param "autosave" to identify it
// Sample: <form data-controller="form-autosave"></form>
export default class extends Controller {
  declare element: HTMLFormElement;
  declare interval:any;

  initialize() {
    this.interval = setInterval(this.saveData.bind(this), 10 * 1000);
  }

  async saveData() {
    const reqData = {
      method: 'post',
      headers: { ...requestHeaders(), ...{} },
      body: new FormData(this.element),
    };
    await fetch(concatUrlParams(this.element.action, 'autosave=true'), reqData);
    this.showStatus();
  }

  disconnect() {
    if (this.interval) clearInterval(this.interval);
  }

  showStatus() {
    const tpl = `<div class="form-autosave-status position-relative">
      <i class="fa fa-check text-success position-absolute end-0 me-2" data-controller="tooltip" 
        title="${I18n('auto_saved')}"></i>
    </div>`;
    this.element.insertAdjacentHTML('afterbegin', tpl);
    setTimeout(() => this.element.querySelector('.form-autosave-status').remove(), 3 * 1000);
  }
}
