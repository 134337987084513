import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  declare element: HTMLInputElement;

  initialize() {
    this.element.placeholder = 'DD-MM-AAAA';
    this.element.addEventListener('keyup', this.recalcValue.bind(this), false);
  }

  recalcValue(ev) {
    let v = this.element.value.replace(/\D/g, '');
    const lastChar = (this.element.getAttribute('data-value') || v).slice(-1);
    if (ev.keyCode === 8 && lastChar === '-') v = v.slice(0, v.length - 1);

    const format = [v.slice(0, 2), v.slice(2, 4), v.slice(4, 8)];
    const res = format.filter((d) => !!d);
    if (v.length === 2) res[1] = '';
    if (v.length === 4) res[2] = '';
    this.element.value = res.join('-');
    this.element.setAttribute('data-value', this.element.value);
  }
}
