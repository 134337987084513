import { Controller } from '@hotwired/stimulus';

// Sample: <canvas data-value="{...}" data-controller="chart" data-title="Title here">
export default class extends Controller {
  declare element: HTMLCanvasElement;
  declare value: { labels: [], datasets: object[] };

  initialize() {
    this.value = JSON.parse(this.element.getAttribute('data-value'));
    this.value.datasets.forEach((item) => {
      item.borderColor = item.color;
      item.backgroundColor = item.color;
    });
    setTimeout(() => new Chart(this.element, this.config()), 1000);
  }

  config() {
    return {
      type: 'bar',
      data: this.value,
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        responsive: false,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
          },
        },
      },
    };
  }
}
