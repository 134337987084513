import { Controller } from '@hotwired/stimulus';

// Adds the ability to toggle panels based on the value of a select element
// Sample:
// <div data-controller="select-switcher">
//   <select data-select-switcher-target="dropdown"><option value="g1" /> <option ... /></select>
//   <div data-select-switcher-target="panels" data-switcher-key="g1">Content for G1</div>
//   <div data-select-switcher-target="panels" data-switcher-key="g2">Content for G2</div>
// </div>

export default class extends Controller {
  static targets = ['dropdown', 'panels', 'remoteframe'];
  declare element: HTMLElement;
  declare dropdownTarget: HTMLSelectElement;
  declare panelsTargets: HTMLElement[];
  declare remoteframeTarget: HTMLElement;
  declare hasRemoteframeTarget: boolean;

  connect() {
    this.dropdownTarget.addEventListener('change', this.switchPanel.bind(this), false);
    this.switchPanel();
  }

  switchPanel() {
    this.panelsTargets.map((panel) => panel.classList.add('d-none'));
    this.panelsTargets.map((panel) => this.changeDisabledStateFor(panel, true));
    const activePanel = this.panelsTargets
      .find((panel) => panel.getAttribute('data-switcher-key').split(',').includes(this.dropdownTarget.value));
    if (activePanel) {
      activePanel.classList.remove('d-none');
      this.changeDisabledStateFor(activePanel, false);
    }

    if (this.hasRemoteframeTarget) {
      const url = this.dropdownTarget.getAttribute('data-select-switcher-url-value');
      this.remoteframeTarget.setAttribute('src', url.replace('xxx', this.dropdownTarget.value));
      this.remoteframeTarget.innerHTML = '<div class="spinner-grow" role="status"><span class="visually-hidden">Loading...</span></div>';
    }
  }

  changeDisabledStateFor(panel: HTMLElement, flag) {
    panel.querySelectorAll('input, textarea, select').forEach((input) => {
      input.disabled = flag;
    });
  }
}
