import { Controller } from '@hotwired/stimulus';
import { debounce, isInViewport } from '../helpers/misc';
import I18n from '../helpers/translations';

// Adds infinite scroll pagination functionality to any panel.
//  Everytime page scroll is moved to the bottom of the page,
//    the corresponding pagination-link is automatically clicked.
// @example:
// .my_huge_panel{ data: { controller: 'infinite-scroll' } }
//   = <my items here>
//   = link_to 'Load more', pagy_next_url(pagy), class: "btn btn-sm btn-primary",
//     'data-infinite-scroll-target' => 'link' #link to auto trigger the next page
//     'data-infinite-scroll-self-scroll-value' => 'true' #to define the scroller (default window)
export default class extends Controller {
  static values = { selfScroll: Boolean };
  static targets = ['link'];
  declare linkTarget: HTMLAnchorElement;
  declare hasLinkTarget: boolean;
  declare selfScrollValue: boolean;
  declare scrollDebounced: () => void;

  initialize() {
    this.scrollDebounced = debounce(this.onScroll.bind(this));
    this.scroller().addEventListener('scroll', this.scrollDebounced, false);
  }

  disconnect() {
    this.scroller().removeEventListener('scroll', this.scrollDebounced);
  }

  scroller() {
    return this.selfScrollValue ? this.element.parentElement : window;
  }

  onScroll() {
    if (!this.hasLinkTarget) return;
    if (this.linkTarget.classList.contains('disabled')) return;

    if (isInViewport(this.linkTarget, 220)) {
      this.linkTarget.classList.add('disabled');
      this.linkTarget.innerHTML = I18n('loading');
      this.linkTarget.click();
    }
  }
}
