import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    url: String, paramName: String, parentField: String, context: { default: 'form' },
  };
  declare urlValue: string;
  declare paramNameValue: string;
  declare parentFieldValue: string;
  declare contextValue: string;
  declare element: HTMLSelectElement;

  initialize() {
    this.loadRemoteData();
    this.bindDependency();
  }

  loadRemoteData() {
    const that = this;
    const url = `${this.urlValue}?${this.paramNameValue}=${this.parentSelect().value}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => that.applyRemoteData(data))
      .catch((error) => {
        console.error(error);
      });
  }

  applyRemoteData(data: { value: string, label: string }[]) {
    const currentVal = this.element.value;
    this.element.querySelectorAll('option').forEach((option) => {
      if (option.value !== '') option.remove();
    });
    data.forEach((item) => {
      this.element.insertAdjacentHTML('afterbegin', `<option value="${item.value}">${item.label}</option>`);
    });
    this.element.value = currentVal || '-unselect-default-';
  }

  bindDependency() {
    this.parentSelect().addEventListener('change', () => this.loadRemoteData(), false);
  }

  parentSelect() {
    const context = this.element.closest(this.contextValue);
    return context.querySelector<HTMLSelectElement>(this.parentFieldValue);
  }
}
