import { Controller } from '@hotwired/stimulus';
import {addClasses, wrapEle} from '../helpers/selectors';

// Wraps panel with horizontal arrows to simulate slider
// Sample: .div{ 'data-controller': 'hslider' }
export default class ModalController extends Controller {
  declare element: HTMLDivElement;
  declare wrapper: HTMLDivElement;
  declare leftBtn: HTMLAnchorElement;
  declare rightBtn: HTMLAnchorElement;

  initialize() {
    this.wrapper = wrapEle(this.element);
    this.addArrows();
    this.bindEvents();
    this.checkScroll();
    window.addEventListener('resize', this.checkScroll.bind(this));
  }

  disconnect() {
    window.removeEventListener('resize', this.checkScroll, false);
  }

  addArrows() {
    const leftLnk = '<a href="#" class="btn btn-sm btn-l position-absolute rounded-pill btn-light"><i class="fa fa-angle-double-left"></i></a></a>';
    const rightLnk = '<a href="#" class="btn btn-sm btn-r position-absolute rounded-pill btn-light end-0"><i class="fa fa-angle-double-right"></i></a></a>';
    addClasses(this.wrapper, ['position-relative', 'd-flex', 'align-items-center']);
    this.wrapper.insertAdjacentHTML('afterbegin', leftLnk);
    this.wrapper.insertAdjacentHTML('beforeend', rightLnk);
    this.element.style.overflowX = 'hidden';
    this.element.style.scrollBehavior = 'smooth';
  }

  bindEvents() {
    this.leftBtn = this.wrapper.querySelector('.btn-l');
    this.rightBtn = this.wrapper.querySelector('.btn-r');
    this.leftBtn.addEventListener('click', (e) => { e.preventDefault(); this.moveScroll(-250); });
    this.rightBtn.addEventListener('click', (e) => { e.preventDefault(); this.moveScroll(250); });
  }

  moveScroll(incr) {
    this.element.scrollTo(this.element.scrollLeft + incr, 0);
    setTimeout(() => this.checkScroll(), 400);
  }

  checkScroll() {
    const isEnd = Math.abs(this.element.scrollWidth - this.element.scrollLeft - this.element.clientWidth) < 10;
    const isStart = this.element.scrollLeft < 10;
    this.updateClass(this.leftBtn, isStart, 'invisible');
    this.updateClass(this.rightBtn, isEnd, 'invisible');
  }

  updateClass(ele: HTMLElement, flag: boolean, klass: string) {
    if (flag) ele.classList.add(klass);
    else ele.classList.remove(klass);
  }
}
