import { Controller } from '@hotwired/stimulus';

// Allows any input/select change to trigger a link with href replaced by the input value
// Sample: <input data-controller="onchange-trigger-link" data-trigger-link='#my-link'>
//         <a href='' id='my-link'>
export default class extends Controller {
  declare element: HTMLInputElement;
  declare origHref: string;
  declare link: HTMLAnchorElement;

  initialize() {
    this.link = document.querySelector<HTMLAnchorElement>(this.element.getAttribute('data-trigger-link'));
    this.origHref = this.link.href;
    this.element.addEventListener('change', this.triggerLink.bind(this), false);
  }

  triggerLink() {
    this.link.href = this.origHref.replace('xx', this.element.value);
    this.link.click();
  }
}
