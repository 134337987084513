import { Controller } from '@hotwired/stimulus';
import {loadJs} from "../helpers/misc";

// Allows to embed any external video
// <div data-controller="embed-video" data-src="youtube.com/asas"></div>
export default class extends Controller {
  declare element: HTMLDivElement;
  declare url: string;
  declare ytKey: string;

	initialize() {
    this.url = this.element.getAttribute('data-src');
    this.element.classList.add('position-relative');
    this.ytKey = this.youtubeParseKey();
    if (this.ytKey) this.previewYT();
    else if (this.url.includes('tiktok.com/')) this.showTiktok();
    else this.element.innerHTML = this.url;
  }

  previewYT() {
    this.element.style.cursor = 'pointer';
    this.element.innerHTML = `<img src="https://i.ytimg.com/vi/${this.ytKey}/maxresdefault.jpg" style="width: 100%" />
      <i class="fa-solid fa-play position-absolute top-50 start-50 fs-1 text-secondary translate-middle-y w-auto" style="height: auto" />`;
    this.element.addEventListener('click', (e) => {
      e.preventDefault();
      this.element.innerHTML = `<iframe src="https://www.youtube.com/embed/${this.ytKey}" allowfullscreen="allowfullscreen" allow="fullscreen;" />`;
    });
  }

  showTiktok() {
    const id = this.url.split('?')[0].split('video/').pop()
    this.element.classList.remove('ratio');
    this.element.innerHTML = `
      <blockquote class="tiktok-embed" cite="${this.url}" data-video-id="${id}" data-embed-from="oembed"> <section> </section> </blockquote>
    `;
    loadJs('https://www.tiktok.com/embed.js');
  }

  youtubeParseKey() {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = this.url.match(regExp);
    return (match && match[7].length === 11) ? match[7] : null;
  }
}
