import { Controller } from '@hotwired/stimulus';

// Sample: <ul data-controller="appointment-rooms-column">
export default class extends Controller {
  declare element: HTMLElement;
  declare colorsData: object;
  declare roomId: string;

  initialize() {
    this.colorsData = JSON.parse(this.element.getAttribute('data-colors'));
    this.roomId = this.element.getAttribute('data-room-id');
    this.element.querySelectorAll('[data-slot-bars]').forEach(this.tplForOffices.bind(this));
  }

  tplForOffices(el) {
    const slotsData = JSON.parse(el.getAttribute('data-slot-bars'));
    const bars = [];
    const options = [];
    slotsData.forEach((item: { id: number, office_id: number }) => {
      const colorData = this.colorsData[item.office_id.toString()];
      bars.push(`<div class="vbar" data-controller="tooltip" style="background-color: ${colorData.color}" title="${colorData.doctor_name}"></div>`);
      options.push(`<li><a class="dropdown-item" data-controller="modal2" style="color: ${colorData.color};" href="/patients_panel/${item.id}/appointments/new?clinics_room_id=${this.roomId}">${colorData.doctor_name}</a></li>`);
    });
    const tpl = `
      ${bars.join('')}
      <button aria-expanded="false" class="btn-doctors btn btn-sm btn-secondary" data-bs-toggle="dropdown">
        <i class="fa fa-calendar-plus"></i>
      </button>
      <ul aria-labelledby="dropdownMenuButton1" class="dropdown-menu">${options.join('')}</ul>`;
    el.innerHTML = tpl;
  }
}
