import { Controller } from '@hotwired/stimulus';
import I18n from '../../helpers/translations';

// Sample:
// div{ data-controller: 'form-dropdown' } <select />
export default class extends Controller {
  static values = {
    sort: { type: Boolean, default: true },
    searchGroups: { type: Boolean, default: false },
    toggledGroups: { type: Boolean, default: false },
  };
  declare element: HTMLElement;
  declare defaultOption: string;
  declare dropdown: HTMLElement;
  declare select: HTMLSelectElement;
  declare sortValue: boolean;
  declare searchGroupsValue: boolean;
  declare toggledGroupsValue: boolean;
  declare toggledGroupClass: string;
  declare remoteOptionsUrl: string;
  declare remoteOptionsLoaded: boolean;
  declare textAlign: string;

  initialize() {
    this.toggledGroupClass = 'closed';
    this.select = this.element.querySelector('select');
    this.textAlign = this.select.classList.contains('text-end') ? 'text-end' : 'text-start';
    this.defaultOption = this.select.querySelector('option[value=""]')?.textContent || `-- ${I18n('select')} --`;
    this.remoteOptionsUrl = this.select.getAttribute('data-remote-url');
    this.buildDropdown();
    this.bindEvents();
  }

  buildDropdown() {
    this.element.querySelector('.form-dropdown')?.remove();
    const tpl = `<div class="dropdown ${this.select.getAttribute('class')}">
      <button class="btn border dropdown-toggle w-100 d-flex justify-content-between align-items-center ${this.textAlign}" type="button" data-bs-toggle="dropdown">
        <span class="dropdown-text text-truncate"></span>
        <span class="caret"></span>
      </button>
      <ul class="dropdown-menu"></ul>
    </div>`;
    this.select.classList.add('d-none');
    this.select.insertAdjacentHTML('afterend', tpl);
    this.dropdown = this.select.nextElementSibling;
    this.buildDropdownOptions();
  }

  buildDropdownOptions() {
    this.dropdown.querySelector('.dropdown-menu').innerHTML = this.dropdownOptions();
    this.updateText();
  }

  focusSearch() {
    setTimeout(() => this.dropdown.querySelector('input[type="search"]')?.focus(), 1);
  }

  selectOption(e) {
    e.preventDefault();
    if (this.select.multiple) e.stopPropagation();
    const li = e.target.closest('li');
    const checkbox = li.querySelector<HTMLInputElement>('input');

    // hack: wait for the checkbox to be checked when clicked directly on the checkbox
    setTimeout(() => {
      this.updateCheckboxes([checkbox]);
      if (!this.select.multiple) this.dropdown.blur();
    }, 0);
  }

  selectAll(e) {
    e.preventDefault();
    e.stopPropagation();
    const checkbox = e.target.closest('li').querySelector<HTMLInputElement>('input');
    const checkboxes = this.dropdown.querySelectorAll<HTMLInputElement>('li:not(.d-none) input.option-input');
    setTimeout(() => { // Fix checked checkbox
      checkbox.checked = !checkbox.checked;
      this.updateCheckboxes(checkboxes, checkbox.checked);
    }, 0);
  }

  updateCheckboxes(checkboxes, forcedValue = null) {
    if (!this.select.multiple) this.dropdown.querySelectorAll('.dropdown-item').forEach((option) => option.classList.remove('active'));
    checkboxes.forEach((checkbox) => {
      checkbox.checked = forcedValue !== null ? forcedValue : !checkbox.checked;
      this.select.querySelector(`option[value="${checkbox.value}"]`).selected = checkbox.checked;

      const option = checkbox.closest('.dropdown-item');
      if (checkbox.checked) option.classList.add('active');
      else option.classList.remove('active');
    });
    this.select.dispatchEvent(new Event('change'));
    this.updateText();
  }

  updateText() {
    let text = null;
    if (this.select.multiple) {
      const selected = this.element.querySelectorAll('option:checked');
      text = selected.length ? `${I18n('selected_items', { qty: selected.length })}` : this.defaultOption;
    } else {
      text = this.select.querySelector('option:checked')?.textContent || this.defaultOption;
    }
    this.dropdown.querySelector('.dropdown-text').textContent = text;
  }

  filterOptions(e) {
    const query = e.target.value.toLowerCase();
    if (this.searchGroupsValue) {
      this.dropdown.querySelectorAll('.group-item').forEach((group) => {
        if (group.getAttribute('data-label').toLowerCase().includes(query)) group.classList.remove('d-none');
        else group.classList.add('d-none');
      });
    } else {
      this.dropdown.querySelectorAll<HTMLInputElement>('input.option-input').forEach((input) => {
        const li = input.closest('li');
        li.querySelector('label').textContent.toLowerCase().includes(query) ? li.classList.remove('d-none') : li.classList.add('d-none');
      });
    }
    this.updateDropdownPosition();
  }

  updateDropdownPosition() {
    document.body.dispatchEvent(new Event('scroll'));
  }

  preventClose(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  selectGroup(e) {
    this.preventClose(e);
    const li = e.target.closest('li');
    const checkbox = li.querySelector<HTMLInputElement>('input.group-input');
    const checkboxes = li.querySelectorAll<HTMLInputElement>('li:not(.d-none) input.option-input');
    setTimeout(() => { // Fix checked checkbox
      checkbox.checked = !checkbox.checked;
      this.updateCheckboxes(checkboxes, checkbox.checked);
    }, 0);
  }

  toggleGroup(e) {
    this.preventClose(e);
    const li = e.target.closest('li');
    li.classList.toggle(this.toggledGroupClass);
  }

  dropdownOptions() {
    let tpl = `
      <li>
        <input type="search" class="form-control search form-control-smm" placeholder="${I18n('type_here')}" data-action="keyup->form-dropdown#filterOptions search->form-dropdown#filterOptions"/>
      </li>`;
    if (this.select.multiple) {
      tpl += `<li data-action="click->form-dropdown#preventClose">
                <div class="dropdown-item fw-bold disabled">
                  <label style="pointer-events: auto;" data-action="click->form-dropdown#selectAll"><input type="checkbox" class="selectall" /><span class="select-text small fst-italic"> ${I18n('select_all')}</span></label></div>
              </li>`;
    }
    tpl += '<li class="divider"></li>';

    if (this.select.querySelector('optgroup')) {
      this.select.querySelectorAll('optgroup').forEach((optgroup) => {
        const selectGroup = `<label class="small" style="pointer-events: auto;" data-action="click->form-dropdown#selectGroup"><input type="checkbox" class="group-input"> ${I18n('select_all')}</label>`;
        tpl += `<li class="group-item ${this.toggledGroupsValue ? this.toggledGroupClass : ''}" data-label="${optgroup.label}">
          <div class="dropdown-item disabled d-flex justify-content-between border-top border-bottom align-items-start" data-action="click->form-dropdown#preventClose">
            <span class="fw-bold cursor-pointer flex-fill" style="pointer-events: auto;" data-action="click->form-dropdown#toggleGroup">
              <span class="carets">
                <i class="fa fa-caret-right"></i>
                <i class="fa fa-caret-down"></i>
              </span>
              ${optgroup.label}
            </span>
            ${this.select.multiple ? selectGroup : ''}
          </div>
          <ul class="m-0 p-0 group-items">
            ${this.parseOptions(Array.from(optgroup.querySelectorAll('option')))}
          </ul>
        </li>`;
      });
    } else {
      tpl += this.parseOptions(Array.from(this.select.querySelectorAll('option')));
    }
    return tpl;
  }

  parseOptions(options: HTMLOptionElement[]) {
    let tpl = '';
    if (this.sortValue) {
      options = options.sort((a, b) => b.value.toString() === '' ? 1 : a.textContent.localeCompare(b.textContent));
    }
    options.forEach((option) => {
      if (option.value === '' && this.select.multiple) return; // skip default option

      const visibleCheck = this.select.multiple && !option.disabled;
      tpl += `<li><a class="dropdown-item" href="#" ${option.disabled ? '' : 'data-action="click->form-dropdown#selectOption"'}>
                <label><input name='options[]' type="${this.select.multiple ? 'checkbox' : 'radio'}" class="option-input ${visibleCheck ? '' : 'd-none'}" value='${option.value}' ${option.selected ? 'checked' : ''}/> 
                  ${option.textContent}</label>
                </a>
              </li>`;
    });
    return tpl;
  }

  bindEvents() { // this.select.dispatchEvent(new CustomEvent('updateOptions', detail: { ids: [1, 2, 3], checked: true }));
    this.select.addEventListener('updateOptions', (e) => {
      const checkboxes = e.detail.ids.map((id) => this.dropdown.querySelector(`input[value="${id}"]`));
      this.updateCheckboxes(checkboxes, e.detail.selected);
    });

    this.select.addEventListener('reload', (ev) => {
      this.loadRemoteOptions(ev.detail.selected);
    });

    this.dropdown.addEventListener('show.bs.dropdown', () => {
      if (this.remoteOptionsUrl && !this.remoteOptionsLoaded) {
        const selectedValues = Array.from(this.select.querySelectorAll('option:checked')).map((option) => option.value);
        this.loadRemoteOptions(selectedValues);
        this.remoteOptionsLoaded = true;
      }
      this.focusSearch();
    });
  }

  loadRemoteOptions(selectedIds = null) {
    selectedIds = Array.isArray(selectedIds) ? selectedIds : [selectedIds];
    this.select.innerHTML = `<option>${I18n('loading')}...</option>`;
    fetch(this.remoteOptionsUrl).then((response) => response.json()).then((data) => {
      const options = data.map((option) => `<option value="${option.id}" ${selectedIds.includes(option.id) || selectedIds.includes(option.id.toString()) ? 'selected' : ''}>${option.label}</option>`);
      this.select.innerHTML = `<option value="">${this.defaultOption}</option> ${options.join('')}`;
      this.buildDropdownOptions();
      this.focusSearch();
      this.updateDropdownPosition();
    }).catch((error) => console.error('Error:', error));
  }

  // remoteSearch(e) {
  //
  // }
}
