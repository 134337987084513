export const openUriTab = () => {
	const tab = document.body.querySelector<HTMLElement>(`.nav-link[data-bs-target="${window.location.hash}"]`);
	tab?.click();
}

window.onload = () => {
	if (window.location.hash) openUriTab();
};

document.addEventListener('turbo:load', (e) => {
	if (window.location.hash) openUriTab();
});
