import { Controller } from '@hotwired/stimulus';
import { loadJs } from '../helpers/misc';

// See info: https://sortablejs.github.io/Sortable/
// Sample: <ul data-controller="sortable">
export default class extends Controller {
  declare element: HTMLElement;

  initialize() {
    loadJs('https://sortablejs.github.io/Sortable/Sortable.min.js', this.buildSortable.bind(this));
  }

  buildSortable() {
    const sortable = Sortable.create(this.element, { dragClass: 'bg-white', animation: 150, handle: '.handle',
      onChange: () => {
        this.element.dispatchEvent(new CustomEvent('updatePositions'));
      },
    });
  }
}
