import { Controller } from '@hotwired/stimulus';

// Sample: <a data-controller="redirect" href="http://my-site.com/?a=true">..</a>
export default class extends Controller {
  declare element: HTMLAnchorElement;

  initialize() {
    window['Turbo'].visit(this.element.href); // eslint-disable-line dot-notation
  }
}
