import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  declare element: HTMLDivElement;

  initialize() {
    this.element.querySelectorAll<HTMLInputElement>('input, select, textarea').forEach((field) => {
      field.readOnly = true;
      field.removeAttribute('name');
    });
  }
}
