import { Controller } from '@hotwired/stimulus';
import { wrapEle } from '../../helpers/selectors';

// Sample: <input type="file" data-controller="filepicker" multiple name="attachments[]" multiple>
// Sample: <input type="file" data-controller="filepicker" multiple name="att" data-as-button="true">
export default class extends Controller {
  declare element: HTMLInputElement;
  declare itemsPanel: HTMLElement;

	initialize() {
    if (this.element.multiple) {
      this.element.classList.add('d-none');
      this.element.insertAdjacentHTML('afterend', `<div class="mb-2 w-50 w-sm-100 last-row-visible-item ${this.element.getAttribute('data-wrapper-class')}"></div>`);
      this.itemsPanel = this.element.nextElementSibling;
      this.addField();
    } else {
      if (this.element.getAttribute('data-as-button')) {
        this.renderAsButton();
      } else {
        this.element.classList.add('form-control');
      }
    }
  }

  renderAsButton() {
    const wrapper = wrapEle(this.element, ['d-inline', 'position-relative']);
    wrapper.insertAdjacentHTML('beforeend', '<button class="btn btn-primary" type="button"><i class="fa fa-upload"></i></button>');
    this.element.setAttribute('class', 'opacity-0 d-bloock position-absolute w-100');
    this.element.style.height = '40px';
  }

  addField() {
    const tpl = `<div class="input-group mb-1">
        <input type="file" class="form-control" multiple accept="${this.element.getAttribute('accept')}" name="${this.element.getAttribute('name')}" ${this.element.required ? 'required=true' : ''} />
        <button class="btn btn-danger btn-sm del-btn" type="button"><i class="fa fa-trash"></i></button>
        <button class="btn btn-info btn-sm add-btn last-row-visible-item" type="button"><i class="fa fa-plus-circle"></i></button>
      </div>`;
    this.itemsPanel.insertAdjacentHTML('beforeend', tpl);
    const lastItem = this.itemsPanel.children[this.itemsPanel.children.length - 1];
    lastItem.querySelector('.del-btn').addEventListener('click', () => lastItem.remove());
    lastItem.querySelector('.add-btn').addEventListener('click', () => this.addField());
  }
}
