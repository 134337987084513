import { Controller } from '@hotwired/stimulus';

// Keeps synced the scroll from other panel with this panel
// Sample: .div{ 'data-controller': 'synced-scrolls', data-synced-scroll-selector='#otherpanel'..
export default class extends Controller {
  declare element: HTMLElement;
  declare timer:any;

  initialize() {
    const selector = this.element.getAttribute('data-synced-scroll-selector');
    const panel2 = selector === ':next' ? this.element.nextElementSibling : document.body.querySelector(selector);
    panel2.addEventListener('scroll', () => this.syncScroll(panel2, this.element), false);
    this.element.addEventListener('scroll', () => this.syncScroll(this.element, panel2), false);
  }

  syncScroll(from: HTMLElement, to: HTMLElement) {
    if (this.timer) clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      to.scrollTop = from.scrollTop;
      to.scrollLeft = from.scrollLeft;
    }, 10);
  }
}
