import { Controller } from '@hotwired/stimulus';

// When off canvas becomes visible, the panel target is turned into visible
// 		(useful for turbo frames inside off canvas)
// Sample: <div class="offcanvas" data-controller="bootstrap-show-on-offcanvas">
//           <div data-bootstrap-show-on-offcanvas-target="target" style="display: none"></div>
//         </div>
export default class extends Controller {
	static targets = ['panel'];
	declare element: HTMLElement;
	declare panelTarget: HTMLElement;

	initialize() {
		this.element.addEventListener('show.bs.offcanvas', () => {
			this.panelTarget.style.display = 'inherit';
		});
	}
}