import { Controller } from '@hotwired/stimulus';
import I18n from '../helpers/translations';

// Sample: .map{ data: { controller: 'map-embed', 'map-embed-marker-value': { lat: @lat, lng: @lng }.to_json } }
export default class extends Controller {
  static values = { marker: Object }
  declare markerValue: { lat: string, lng: string };

  declare element: HTMLDivElement;
  declare map: any;
  declare marker: any;

	initialize() {
    this.initMap();
    this.addMarker();
    this.addMapLink();
  }

  initMap() {
    this.element.insertAdjacentHTML('beforeend', '<div style="height: 500px; width: 100%" class="map"></div>');
    this.map = new google.maps.Map(
      this.element.querySelector('.map'),
      {
        center: { lat: this.markerValue.lat, lng: this.markerValue.lng },
        zoom: 13,
        mapTypeControl: false,
        styles: [{ featureType: "poi.business", stylers: [{ visibility: "off" }] }],
      }
    );
  }

  addMarker() {
    this.marker = new google.maps.Marker({ map: this.map });
    const pos = { lat: this.markerValue.lat, lng: this.markerValue.lng };
    this.map.setCenter(pos);
    this.marker.setPosition(pos);
  }

  addMapLink() {
    const tpl = `<div class="text-end mt-2">
      <a 
        href="https://www.google.com/maps/dir/?api=1&destination=${this.markerValue.lat},${this.markerValue.lng}" 
        target="_blank"
        class="btn btn-info">
        ${I18n('how_to_arrive')}
      </a>
    </div>`;
    this.element.insertAdjacentHTML('afterend', tpl);
  }
}
