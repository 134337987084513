import { Controller } from '@hotwired/stimulus';

// Once selected an option, this value is replaced in the next hidden link.href
// to navigate to the desired page
// Sample:
// <a href="http:///xxx.com"></a><select 'data-controller': 'dropdown-link-dispatcher'>
export default class extends Controller {
  declare element: HTMLSelectElement;
  declare bkUrl: string;
  declare link: HTMLAnchorElement;

  initialize() {
    const maskSelect = this.element.classList.contains('choices__input') || this.element.classList.contains('form-dropdown');
    this.link = (maskSelect ? this.element.closest('.choices') : this.element).previousElementSibling;
    this.bkUrl = this.link.getAttribute('href');
    this.element.addEventListener('change', this.dispatchLink.bind(this), false);
  }

  dispatchLink() {
    const selectedOptions = Array.from(this.element.querySelectorAll('option:checked')).map((option) => option.value);
    const newUrl = this.bkUrl.replace('xxx', selectedOptions.join(','));
    this.link.setAttribute('href', newUrl);
    this.link.click();
  }
}
