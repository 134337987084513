import { Controller } from '@hotwired/stimulus';
import { subscribeToEvent } from '../helpers/events';
import I18n from '../helpers/translations';

// Allow to manage openai panels to filter via specialities
// Sample: <div data-controller="openai-results"> <a href="#" data-value="10"></>
export default class extends Controller {
  static values = { finished: Boolean }

  declare element: HTMLDivElement;
  declare form: HTMLFormElement;
  declare finishedValue: boolean;

  initialize() {
    if (this.finishedValue) this.bindSeeAll();
    const links = Array.from(this.element.querySelectorAll<HTMLElement>('a'));
    this.form = document.querySelector('form.doctors-filter-form');
    subscribeToEvent({ element: links, eventName: ['click'], callback: this.clickLink.bind(this) });
  }

  clickLink(e) {
    e.preventDefault();
    const option = `<option value="${e.target.getAttribute('href')}">${e.target.innerHTML}</option>`;
    this.form.querySelector('[name="q[speciality_id]"]').innerHTML = option;
    this.form.querySelector('[type="submit"]').click();
  }

  bindSeeAll() {
    const ids = Array.from(this.element.querySelectorAll<HTMLElement>('a'))
      .map((lnk) => lnk.getAttribute('href'));
    this.element.insertAdjacentHTML('beforeend', `<div class="text-center mt-3">
      <a href="${ids.join(',')}" class="btn btn-primary">${I18n('doctors_list')}</a>
    </div>`);
  }
}
