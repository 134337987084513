import { Controller } from '@hotwired/stimulus';
import { loadJs } from '../../helpers/misc';
import {ajaxRequest} from "../../lib/turbo_request";
import I18n from "../../helpers/translations";
import {renderFlash} from "../../helpers/flash";


// Sample: <div data-controller="appointment-record-audio">
export default class extends Controller {
  static values = { url: String };
  declare element: HTMLElement;
  declare recorder: object;
  declare urlValue: string;
  declare currentBlob: Blob;
  declare prevEle: HTMLElement;

  initialize() {
    this.prevEle = this.element.previousElementSibling as HTMLElement;
    loadJs('https://cdn.jsdelivr.net/npm/simple-audio-recorder@0.0.3/dist/audiorecorder.js', () => {
      this.buildRecorder();
    });
  }

  buildRecorder() {
    AudioRecorder.preload("https://cdn.jsdelivr.net/npm/simple-audio-recorder@0.0.3/dist/mp3worker.js");
    this.element.classList.add('audio-recorder-container');
    this.recorder = new AudioRecorder();
    this.element.innerHTML = this.tpl();
    this.showButtons(['record']);
  }

  start() {
    this.recorder.start().then(() => {
      this.showButtons(['stop', 'pause']);
    }).catch(error => {
      console.log(error);
    });
  }

  resume() {
    this.recorder.resume();
    this.showButtons(['stop', 'pause']);
  }

  stop() {
    this.recorder.stop().then(mp3Blob => {
      this.currentBlob = mp3Blob;
      this.saveAudio();
    }).catch(error => {
      console.log(error);
    });
  }

  saveAudio() {
    const formData = new FormData();
    formData.append('audio', this.currentBlob, 'audio.mp3');
    formData.append('current_value', this.prevEle.value);
    this.showButtons(['record', 'loading']);
    ajaxRequest(this.urlValue, { loadingState: true, method: 'POST', data: formData })
      .then((response) => response.json())
      .then((res) => { // returns: { appointment_doctor_note: '', appointment_receipt: '', ... }
        Object.keys(res).forEach((key) => {
          this.element.closest('form').querySelector(`[id="${key}"]`).value = res[key];
        });
        this.prevEle.scrollTop = this.prevEle.scrollHeight;
        this.prevEle.classList.add('fade_animation');
        this.showButtons(['record']);
        setTimeout(() => this.prevEle.classList.remove('fade_animation'), 1000);
      }).catch((err) => {
        console.error('Failed processing audio:', err);
        renderFlash(I18n('unknown_error'));
        this.showButtons(['record', 'save', 'download']);
      });
  }

  pause() {
    this.recorder.pause();
    this.showButtons(['stop', 'resume']);
  }

  download() {
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);
    a.href = URL.createObjectURL(this.currentBlob);
    a.download = 'audio.mp3';
    a.click();
    URL.revokeObjectURL(a.href);
    a.remove();
  }

  showButtons(buttons: string[]) {
    this.element.querySelectorAll('.controls button').forEach((button: HTMLElement) => button.classList.add('d-none'));
    buttons.forEach((button: string) => this.element.querySelector(`button.${button}`).classList.remove('d-none'));
  }

  tpl() {
    return `
      <div class="text-end">
        <div class="btn-groupp controls">
          <button class="record btn btn-sm btn-primary" type="button" data-action="click->appointment-record-audio#start" data-controller="tooltip" title="${I18n('record_ia_clinic_rec')}"><i class="fa fa-play"></i></button>
          <button class="pause btn btn-sm btn-primary" type="button" data-action="click->appointment-record-audio#pause" data-controller="tooltip" title="${I18n('controls.pause')}"><i class="fa fa-pause"></i></button>
          <button class="resume btn btn-sm btn-primary" type="button" data-action="click->appointment-record-audio#resume" data-controller="tooltip" title="${I18n('controls.resume')}"><i class="fa fa-eject fa-rotate-90"></i></button>
          <button class="stop btn btn-sm btn-danger" type="button" data-action="click->appointment-record-audio#stop" data-controller="tooltip" title="${I18n('controls.stop')}"><i class="fa fa-stop"></i></button>
          <button class="save btn btn-sm btn-success" type="button" data-action="click->appointment-record-audio#saveAudio" data-controller="tooltip" title="${I18n('controls.save')}"><i class="fa fa-save"></i></button>
          <button class="download btn btn-sm btn-info" type="button" data-action="click->appointment-record-audio#download" data-controller="tooltip" title="${I18n('controls.download')}"><i class="fa fa-download"></i></button>
          <button class="loading btn btn-sm" type="button"><i class="fa fa-spinner fa-spin"></i></button>
        </div>
      </div>
      <ul class="audios list-group"></ul>
    `;
  }
}
