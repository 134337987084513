import { Controller } from '@hotwired/stimulus';
import { toggleClass } from '../helpers/selectors';

// Sample: <span data-controller="frame-toggle-class" data-toggle-class="my-class" />
export default class extends Controller {
  declare element: HTMLElement;
  initialize() {
    const frame = this.element.closest('turbo-frame') || document.body;
    this.element.addEventListener('click', () => {
      toggleClass(frame, this.element.getAttribute('data-toggle-class'));
      this.element.blur();
    });
  }
}
