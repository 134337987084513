import { Controller } from '@hotwired/stimulus';

// Adds custom behavior for tabs feature to use parent context to find the target content
//  instead of the entire body of the document.

// Sample:
// %ul.nav.nav-tabs.justify-content-end{ 'data-controller': "#{turbo_frame_request? ? '' : 'tabs-manager'} context-tabs" }
//  %li %button.nav-link.text-danger{'data-bs-target': "#tab_cancel_appointment", "data-bs-toggle" => "custom", type: 'button', 'data-tab-value': 'cancel'}

// Note: use "data-bs-toggle" => "custom" to skip bs default behavior
export default class extends Controller {
  declare element: HTMLCanvasElement;

  initialize() {
    this.element.querySelectorAll('[data-bs-target]').forEach((tab: HTMLElement) => {
      tab.addEventListener('click', (e) => {
        if (tab.getAttribute('data-tab-remote')) {
          this.showTab(tab, true);
        } else {
          e.preventDefault();
          this.showTab(tab);
        }
      });
    });
  }

  showTab(tab: HTMLElement, resetContent = false) {
    const target = this.element.parentElement.querySelector(`:scope > div > ${tab.dataset.bsTarget}`);
    if (target) {
      this.element.querySelectorAll('[data-bs-target].active').forEach((el) => {
        el.classList.remove('active');
        const oldPane = this.element.parentElement.querySelector(`:scope > div > ${el.dataset.bsTarget}`);
        oldPane.classList.remove('active');
        oldPane.classList.remove('show');
      });
      if (resetContent) target.innerHTML = '';
      target.classList.add('active');
      target.classList.add('show');
      tab.classList.add('active');
    }
  }
}
