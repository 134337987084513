import { Controller } from '@hotwired/stimulus';
import I18n from '../helpers/translations';

// Add the ability to load tpl content from server via ajax and add it to listTarget
// Sample:
//  .col-6.fields-list{ 'data-controller': 'repeatable-field' }
//   = required_label f_doctor, :language_ids do |label|
//     = label
//     = link_to '#', class: 'btn btn-sm', 'data-repeatable-field-target' => 'button' do
//   %i.fa.fa-plus-circle
//   %ul.p-0{ 'data-repeatable-field-target' => 'list' }
//   = f_doctor.fields_for :doctor_languages, (f_doctor.object.doctor_languages) do |f_lang|
//   %li.list-group-item.d-flex{ 'data-repeatable-field-target': f_lang.object.id ? '' : 'tpl'  }
//   = f_lang.hidden_field :id
//   = f_lang.collection_select :language_id, Language.all, :id, :title, {}, class: 'form-select '
//   = f_lang.select :level, DoctorLanguage.available_levels.invert.to_a, {}, class: 'form-select'
//   = f_lang.check_box :_destroy, class: 'repeatable-field-destroy-check' if f_lang.object.id
//   = link_to '<i class="fa fa-trash"></i>'.html_safe, '#', class: 'repeatable-field-destroy'
//  .repeatable-field-destroy-check checkbox to mark as checked if exist
//  .repeatable-field-destroy destroy link
//  'data-repeatable-field-update-name-value': 'true' => Allows to update index on input name
//  'data-repeatable-field-rename-last-entry-value': 'true' => Renames index name to the last entry
//  With importer:
//    %button.btn.btn-sm.btn-secondary.dropdown-toggle.ms-1{ type: 'button', 'data-bs-toggle': 'dropdown',
//      'data-repeatable-field-target': 'import', 'data-import-columns': [[ID Name], [1, 'Test']] }
export default class extends Controller {
  static targets = ['button', 'list', 'tpl', 'import'];
  static values = {
    updateName: { type: Boolean, default: true },
    renameLastEntry: { type: Boolean, default: false },
    index: { type: Number, default: -1 },
    addDefault: { type: Boolean, default: true },
  };
  declare element: HTMLElement;
  declare buttonTarget: HTMLLinkElement;
  declare listTarget: HTMLUListElement;
  declare tplTarget: HTMLElement;
  declare importTarget: HTMLElement;
  declare hasImportTarget: boolean;
  declare hasButtonTarget: boolean;

  declare tpl:string;
  declare tpls: { string: string};
  declare newItemsCounter:number;
  declare updateNameValue:boolean;
  declare renameLastEntryValue:boolean;
  declare indexValue:number;
  declare addDefaultValue:boolean;

  initialize() {
    this.tpls = {};
    this.newItemsCounter = this.indexValue;
    this.saveTpl();
    this.saveTpls();
    if (this.hasButtonTarget) this.buttonTarget.addEventListener('click', this.addItem.bind(this));
    this.listTarget.addEventListener('click', this.checkDeleteItem.bind(this));
    if (this.renameLastEntryValue) {
      this.newItemsCounter -= 1;
      this.updateEntryFields();
    }
    this.checkDefault();

    // Rename data-controller-toggle to data-controller to perform callbacks
    this.toggleController(this.element.querySelectorAll('[data-controller-toggle]'));

    if (this.hasImportTarget) this.buildImporter();
    this.updatePositions();
    this.listTarget.addEventListener('updatePositions', this.updatePositions.bind(this));
  }

  saveTpl() {
    const clone = this.tplTarget.cloneNode(true) as HTMLElement;
    this.tpl = clone.outerHTML;
    this.tplTarget.remove();
  }

  saveTpls() {
    const key = 'data-repeatable-tpl';
    this.element.querySelectorAll(`[${key}]`).forEach((item) => {
      this.tpls[item.getAttribute(key)] = item.outerHTML;
      item.remove();
    });
  }

  addGroupBefore(event) {
    this.addItem(event, 'beforebegin', this.tpls.group);
  }

  addItemBefore(event) {
    this.addItem(event, 'beforebegin');
  }

  addItemAfter(event) {
    this.addItem(event, 'afterend');
  }

  addItemTpl(event) {
    const tplValue = this.tpls[event.target.getAttribute('data-repeatable-tpl-key')];
    this.addItem(event, null, tplValue || this.tpl);
  }

  addItem(event = null, targetPosition = null, tpl = this.tpl) {
    if (event) event.preventDefault();
    this.newItemsCounter -= 1;
    let lastRow;
    if (event && targetPosition) {
      const target = event.target.closest('.dropdown-menu')?.closest('li') || event.target.closest('li');
      if (targetPosition === 'afterend') {
        target.insertAdjacentHTML(targetPosition, tpl);
        lastRow = target.nextElementSibling;
      } else {
        target.insertAdjacentHTML(targetPosition, tpl);
        lastRow = target.previousElementSibling;
      }
    } else {
      this.listTarget.insertAdjacentHTML('beforeend', tpl);
      lastRow = this.listTarget.lastElementChild;
    }
    this.toggleController(lastRow.querySelectorAll('[data-controller-toggle]'));
    this.updatePositions();
    if (event) lastRow.querySelector('input[type="text"], textarea, select')?.focus();
    if (!this.updateNameValue) return;

    this.updateEntryFields(lastRow);
  }

  updateEntryFields(lastRow = this.listTarget.lastElementChild) {
    const index = `[${this.newItemsCounter}]`;
    if (!lastRow) return;

    lastRow.querySelectorAll('input, textarea, select').forEach((item) => {
      item.setAttribute('name', item.getAttribute('name').replace(/\[\]|\[0\]/, index));
    });
  }

  checkDeleteItem(e) {
    const klassName = 'repeatable-field-destroy';
    let btn = null as HTMLAnchorElement;
    if (e.target.classList.contains(klassName)) btn = e.target;
    if (e.target.closest(`.${klassName}`)) btn = e.target.closest(`.${klassName}`);
    if (!btn) return;

    e.preventDefault();
    const checkbox = btn.parentElement.querySelector<HTMLInputElement>('.repeatable-field-destroy-check');
    if (checkbox) {
      checkbox.checked = true;
      btn.closest('li').classList.add('d-none');
      return;
    }
    btn.closest('li').remove();
  }

  checkDefault() {
    if (!this.listTarget.childElementCount && this.addDefaultValue) this.addItem();
  }

  toggleController(items: HTMLElement[]) {
    items.forEach((item) => {
      item.setAttribute('data-controller', item.getAttribute('data-controller-toggle'));
    });
  }

  downloadTpl(e) {
    e.preventDefault();
    const data = JSON.parse(this.importTarget.getAttribute('data-import-columns'));
    const tpl = data.map((items) => items.join(';')).join('\n');
    const blob = new Blob([tpl], { type: 'text/csv' });
    window.location.href = window.URL.createObjectURL(blob);
  }

  importCsv(e) {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (event) => {
      const [, ...data] = (event.target.result as string).split('\n').map((line) => line.split(';'));
      data.forEach((row) => {
        if (row.join('').trim() === '') return;

        if (this.tpls.group && row.filter((n) => n).length === 1) this.addItem(null, null, this.tpls.group);
        else this.addItem();
        const lastRow = this.listTarget.lastElementChild;
        lastRow.querySelectorAll('input:not([type="hidden"]), select').forEach((item, index) => {
          if (item.type === 'number') item.value = row[index].replace(',', '.');
          else item.value = row[index];
        });
      });
    };
    reader.readAsText(file);
  }

  buildImporter() {
    this.importTarget.parentElement.querySelector('.import-dropdown')?.remove();
    const tpl = `<ul class="dropdown-menu import-dropdown">
      <li>
        <input type="file" class="d-none" accept="text/csv" data-action="change->repeatable-field#importCsv" />
        <a href="#" class="dropdown-item" data-controller="click-delegator" data-click-delegator-selector-value=":prev">${I18n('upload')}</a>
      </li>    
      <li>
        <a href="#" class="dropdown-item" data-action="click->repeatable-field#downloadTpl">${I18n('download_tpl')}</a>
      </li>
    </ul>`;
    this.importTarget.insertAdjacentHTML('afterend', tpl);
  }

  updatePositions() {
    this.listTarget.querySelectorAll(':scope > *').forEach((item, index) => {
      const field = item.querySelector('[data-repeatable-field-position]');
      if (field) field.value = index + 1;
    });
  }
}
