import { Controller } from '@hotwired/stimulus';
import { Tooltip } from 'bootstrap';

// Sample: <span data-controller="tooltip" title="Some title" />
export default class TooltipController extends Controller {
  declare element: HTMLElement;
  declare tooltip:any;

  initialize() {
    if (!this.element.title && this.element.dataset.title) {
      this.element.title = this.element.dataset.title;
    }
    this.tooltip = new Tooltip(this.element, {});
  }

  disconnect() {
    this.tooltip.dispose();
  }
}
