import { Toast } from 'bootstrap';
import { Controller } from '@hotwired/stimulus';
import {deAssignController} from '../helpers/controllers';

// Sample: <div data-controller="toast" class="bg-danger">My message</div>
export default class extends Controller {
  declare element: HTMLElement;

	initialize() {
    this.parseContent();
    const toast = new Toast(this.element, {});
    toast.show();
    deAssignController(this.element, 'toast');
  }

  parseContent() {
    const btn = '<button aria-label="Close" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" type="button"></button>';
    const html = `<div class="d-flex">
                    <div class="toast-body overflow-auto">
                    ${this.element.innerHTML}
                    </div>
                    ${btn}
                  </div>`;
    this.element.innerHTML = html;
    const classes = 'toast align-items-center text-white border-0'.split(' ');
    this.element.classList.add(...classes);
    this.element.setAttribute('data-bs-autohide', 'true');
  }
}
