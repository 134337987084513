import { Controller } from '@hotwired/stimulus';

// When loaded, auto opens the defined tab or the first tab.
// When tab changed, updates the url with the new key so that,
//  when reloaded it reopens the same tab(s)
// Sample: %ul.nav.nav-tabs.justify-content-start
//            { 'data-controller': 'tabs-manager' }
//            %button.nav-link{'data-bs-target': "#tab_office_1", 'data-tab-value': 1}= office.name
//  where option values are exactly the same as tab data-tab-value
// Note: data-tab-value is expected in the tab links, sample: 'data-tab-value': office.id
// Note: use data-tabs-manager-sync-field-value="#myfield" to define where to save the current value

export default class extends Controller {
  static values = { key: { type: String, default: 'tab' } };
  declare element: HTMLElement;
  declare keyValue: string;
  declare syncFieldValue: string;

  initialize() {
    this.syncFieldValue = this.element.getAttribute('data-tabs-manager-sync-field-value');

    setTimeout(() => { // wait for url update when redirected
      this.focusCurrentTab(!!this.element.closest('.modal')); // avoid push state from onTabClicked
      this.element.querySelectorAll('.nav-link').forEach((link) => {
        link.addEventListener('click', () => this.onTabClicked(link));
      });
    }, 2);
  }

  onTabClicked(tab) {
    const href = new URL(window.location.href);
    const urlParams = href.searchParams;
    const tabValue = tab.getAttribute('data-tab-value');
    urlParams.set(this.keyValue, tabValue);
    this.updateUrl(href.toString());

    if (this.syncFieldValue) this.syncField(tabValue);
  }

  syncField(tabValue: string) {
    const syncInput = document.querySelector<HTMLInputElement>(this.syncFieldValue);
    if (syncInput) syncInput.value = tabValue;
    else console.error(`Tabs-manager: ${this.syncFieldValue} element not found`);
  }

  updateUrl(url) {
    const state = { turbo_stream_history: true };
    Turbo.cache.clear();
    history.replaceState(state, '', url);
  }

  focusCurrentTab(forceFirst = false) {
    let tab = this.element.querySelector<HTMLElement>(`.nav-link[data-tab-value="${this.currentTabValue()}"]`);
    if (!tab || forceFirst) tab = this.element.querySelector<HTMLElement>('.nav-link');
    tab.click();
  }

  currentTabValue() {
    const urlParams = new URL(window.location.href).searchParams;
    return urlParams.get(this.keyValue);
  }
}
