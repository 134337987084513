import { Controller } from '@hotwired/stimulus';

// Sample:
//  = form_for(url: '/', 'data-controller': 'form-validator', novalidate: true do |f|
export default class extends Controller {
  declare element: HTMLFormElement;
  declare validator: any;

	initialize() {
    this.element.classList.add('needs-validation');
    this.element.novalidate = true;
    this.element.setAttribute('novalidate', 'true');
    this.bindValidation();
  }

  bindValidation() {
    this.element.addEventListener('submit', (event) => {
      if (!this.element.checkValidity()) {
        event.preventDefault();
        event.stopPropagation();
        this.element.querySelector('input:invalid, select:invalid')?.focus();
        this.element.classList.add('has-errors');
      } else this.element.classList.remove('has-errors');
      this.element.classList.add('was-validated');
    }, false);
  }
}
