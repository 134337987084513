import { Controller } from '@hotwired/stimulus';
import {renderFlash} from "../helpers/flash";
import I18n from "../helpers/translations";

// Sample:
export default class extends Controller {
  static values = { ratio: { type: Number, default: 16 / 9 }, fill: { type: String, default: '#ffffff' } };
  declare element: HTMLDivElement;
  declare ratioValue: number;
  declare fillValue: string;
  declare cropper: any;
  declare fileFormat: string;

  initialize() {
    this.element.querySelector('input[type="file"]').addEventListener('change', this.setCropper.bind(this));
    this.element.querySelector('button.crop-btn').addEventListener('click', this.cropImg.bind(this));
  }

  setCropper(e) {
    const file = e.target.files[0];
    if (!file) return;
    if (this.cropper) this.cropper.destroy();

    const url = URL.createObjectURL(file);
    const img = this.element.querySelector<HTMLImageElement>('img');
    img.src = url;
    this.cropper = new Cropper(img, { aspectRatio: this.ratioValue, zoomable: true, scalable: false, zoomOnWheel: false });
    this.fileFormat = file.type;
    this.element.querySelector('button.crop-btn').classList.remove('disabled');

    [this.element.querySelector('button.zoomin-btn'), this.element.querySelector('button.zoomout-btn')].forEach((btn) => {
      btn.classList.remove('disabled');
      btn.addEventListener('click', () => {
        this.cropper.zoom(parseFloat(btn.getAttribute('data-zoom')));
      });
    });
  }

  cropImg() {
    let result;
    try {
      result = this.cropper.getCroppedCanvas({ fillColor: this.fillValue }).toDataURL(this.fileFormat);
    } catch (e) {
      window.Sentry.captureException(e);
      return renderFlash(I18n('compatibility_error'));
    }
    this.element.querySelector('form input[name="photo"]').value = result;
    this.element.querySelector('form [type="submit"]').click();
  }
}
