const translations = {
  en: {
    add_new_option: 'Add new option',
    no_options_found: 'No options',
    select: 'Select...',
    see_more: 'See more',
    auto_saved: 'Auto saved',
    loading: 'Loading...',
    type_here: 'Type here...',
    how_to_arrive: 'How to arrive',
    close: 'Close',
    generating_ai: 'We are analyzing your question via AI, please wait...',
    doctors_list: 'See doctors list',
    drag_drop_files: 'Drag \'n\' drop some files here, or click to select files',
    geo_denied: 'Geolocalización was denied and we can not capture your current location. More details here.',
    compatibility_error: 'An error occurred. Please try again. If the issue persists, make sure you are using an updated version of the browser.',
    unknown_error: 'An error occurred. Please try again.',
    ia_words: ' want , would , have , wish , need ',
    command_separated_msg: 'You can paste a list of values separated by comma or line break',
    sub_services: 'Sub services',
    years: 'years',
    days: 'days',
    hours: 'hours',
    minutes: 'minutes',
    total_cost: 'Total cost',
    total_time: 'Total time',
    no_results_found: 'No results found',
    upload: 'Upload file',
    download_tpl: 'Download template',
    select_all: 'Select all',
    selected_items: '{{qty}} items',
    record_ia_clinic_rec: 'Record the medical history using the microphone, to be processed later with artificial intelligence (include additional cost per hour)',
    controls: {
      record: 'Record',
      pause: 'Pause',
      resume: 'Resume',
      stop: 'Stop',
      save: 'Save',
      download: 'Download',
    },
  },
  es: {
    add_new_option: 'Agregar nueva opción',
    no_options_found: 'No hay opciones',
    select: 'Seleccione...',
    see_more: 'Ver más',
    auto_saved: 'Guardado automático',
    loading: 'Cargando...',
    type_here: 'Escribe aquí...',
    how_to_arrive: 'Como llegar',
    close: 'Cerrar',
    generating_ai: 'Estamos analizando su consulta utilizando inteligencia artificial y puede tardar. Por favor, aguarde...',
    doctors_list: 'Ver listado de doctores',
    drag_drop_files: 'Arrastra y suelta los archivos aquí, o haga click para seleccionar archivos',
    geo_denied: 'Geolocalización fué denegada y no podemos detectar tu ubicación actual. Más info aquí.',
    compatibility_error: 'Se produjo un error. Por favor, inténtalo nuevamente. Si el problema persiste, asegúrate de estar utilizando una versión actualizada del navegador.',
    unknown_error: 'Se produjo un error. Por favor, inténtalo nuevamente.',
    ia_words: ' me , quiero , duele , tengo , deseo , necesito ',
    command_separated_msg: 'Puedes pegar una lista de valores separados por coma o salto de línea',
    sub_services: 'Sub servicios',
    years: 'años',
    days: 'días',
    hours: 'horas',
    minutes: 'minutos',
    total_cost: 'Costo total',
    total_time: 'Tiempo total',
    no_results_found: 'No hay resultados',
    upload: 'Subir archivo',
    download_tpl: 'Descargar plantilla',
    select_all: 'Seleccionar todos',
    selected_items: '{{qty}} items',
    record_ia_clinic_rec: 'Graba la historia clínica utilizando el micrófono, para luego ser procesada con inteligencia artificial (costo adicional por hora)',
    controls: {
      record: 'Grabar',
      pause: 'Pausa',
      resume: 'Reanudar',
      stop: 'Detener',
      save: 'Guardar',
      download: 'Descargar',
    },
  },
};

// key: Translation key, like: 'add_new_option' or 'controls.record'
// data: Replacement data, like: { qty: 3 }
const I18n = (key: string, data = {}) => {
  const deepGet = (obj, keys) => keys.reduce((xs, x) => xs?.[x] ?? null, obj);
  const translation = deepGet(translations[window['locale']], key.split('.'));
  const res = translation || key;
  return Object.keys(data).reduce((acc, k) => acc.replace(new RegExp(`{{${k}}}`, 'g'), data[k]), res);
};

export default I18n;
