import { Controller } from '@hotwired/stimulus';

// Allows to listen turbo-response data to include result as a selected option to the current dropdown
// = select_tag 'appo[patient_id]', [], 'data-controller': 'dropdown-listen-request', 'data-frame-value': 'new-internal-patient'
export default class extends Controller {
  static values = { frame: String };
  declare element: HTMLSelectElement;
  declare frameValue: string;

  initialize() {
    document.addEventListener('turbo:before-fetch-response', this.onResponse.bind(this), false);
  }

  disconnect() {
    document.removeEventListener('turbo:before-fetch-response', this.onResponse.bind(this), false);
  }

  onResponse(e) {
    // TODO: document.removeEventListener('turbo:before-fetch-response' is not working for some reason thus isConnected
    if (!this.element.isConnected || !this.frameValue.split(',').includes(e.target.id)) return;

    if (e.detail.fetchResponse.response.status !== 200) return;

    const { id, label } = JSON.parse(e.detail.fetchResponse.response.headers.get('responseOption'));
    const option = `<option value="${id}">${label}</option>`;
    this.element.insertAdjacentHTML('beforeend', option);
    this.element.value = id;
    this.element.dispatchEvent(new Event('change'));
    if (this.element.classList.contains('choices__input')) { // hack to update selected label for choices (there is no way to rebuild choices)
      const parent = this.element.closest('.choices');
      if (parent.querySelector('.choices__item--selectable')) parent.querySelector('.choices__item--selectable').innerHTML = label;
      if (parent.querySelector('.choices__list--single')) parent.querySelector('.choices__list--single').innerHTML = label;
    }
  }
}
