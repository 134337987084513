import { Controller } from '@hotwired/stimulus';
// Generates the sticky headers for calendar columns
// Sample: <div data-controller="calendar-generate-sticky">
export default class extends Controller {
  declare element: HTMLElement;
  declare height: string;
  declare timer:any;

  initialize() {
    this.height = `${this.element.getAttribute('data-height') || 42}px`;
    window.addEventListener('resize', this.onResize.bind(this), false);
    this.rebuildColumns();
  }

  disconnect() {
    window.removeEventListener('resize', this.onResize.bind(this), false);
  }

  columnsTpl() {
    return Array.from(this.element.querySelectorAll('.grid-column'))
      .map((col: HTMLElement) => {
        const title = col.querySelector('.col-subtitle');
        if (!title.dataset.html) title.dataset.html = title.innerHTML;
        title.innerHTML = '&nbsp;';
        return `<div class="border" style="width: ${this.getWidth(col)}px">${title.dataset.html}</div>`;
      }).join('');
  }

  getWidth(el) {
    return el.getBoundingClientRect().width;
  }

  onResize() {
    if (this.timer) clearTimeout(this.timer);

    const that = this;
    this.timer = setTimeout(() => that.rebuildColumns(), 100);
  }

  rebuildColumns() {
    const tpl = `
      <div class="sticky-top column-titles-bar bg-white" style="line-height: ${this.height}" data-controller="synced-scroll" data-synced-scroll-selector=":next">        
        <div class=" d-flex text-center fw-bold" style="width: ${this.element.scrollWidth}px; overflow: hidden; white-space: nowrap;">${this.columnsTpl()}</div>
      </div>
    `;
    this.element.parentElement.querySelector('.column-titles-bar')?.remove();
    this.element.insertAdjacentHTML('beforebegin', tpl);
  }
}
