import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap';
import I18n from '../helpers/translations';
import { ajaxRequest, closeActiveModal } from '../lib/turbo_request';

// Examples:
//  * Self content
//    <div data-controller="modal2"
//       data-modal2-size-value="modal-lg text-danger"
//       data-modal2-self-modal-value="true">${body}</div>
//  * Modal link
//    <a href="my_link" data-controller="modal2"></a>
//    <a href="my_link" data-modal2-target-value="#panel-content"></a>
//    <a href="my_link" data-modal2-footerclose-value="true"></a>

let modalsCounter = 1;
export default class extends Controller {
  static values = { body: String,
    title: String,
    target: String,
    size: { type: String, default: 'modal-xl' },
    selfModal: Boolean,
    footerClose: Boolean,
  };
  declare bodyValue: string;
  declare titleValue: string;
  declare targetValue: string;
  declare sizeValue: string;
  declare selfModalValue: boolean;
  declare footerCloseValue: boolean;
  declare element: HTMLAnchorElement|HTMLButtonElement;
  declare modalId: string;
  declare modalPanel: HTMLDivElement;

  initialize() {
    const that = this;
    if (this.selfModalValue) {
      this.buildModal();
      setTimeout(() => {
        document.querySelector(`#${this.modalId}-body`).insertAdjacentElement('beforeend', this.element);
      }, 5);
      this.element.remove();
    } else {
      this.element.addEventListener('click', (e) => {
        const closeModal = that.element.getAttribute('data-turbo-request-close-modal');
        if (closeModal) closeActiveModal(); // close current modal before fetching the new modal

        that.buildModal();
        if (that.element.tagName === 'A' && !this.targetValue) {
          const frameId = `${this.modalId}-frame`;
          that.element.setAttribute('data-turbo-frame', frameId);
          document.querySelector(`#${this.modalId}-body`)
            .insertAdjacentHTML('beforeend', `<turbo-frame id="${frameId}" data-turbo-response-action="replace"></turbo-frame>`);
        } else {
          e.preventDefault();
          e.stopPropagation();
        }
      });
    }
  }

  loadRemoteContent(method) {
    const attrs = { method, headers: { 'Turbo-Frame': `${this.modalId}-body` } };
    setTimeout(() => { // wait for link-sync controller
      ajaxRequest(this.element.href, attrs)
        .then((r) => r.text())
        .then((res) => Turbo.renderStreamMessage(res));
    }, 5);
  }

  tpl(content) {
    const title = this.titleValue || this.element.getAttribute('data-title') || '';
    const closeTpl = `<div class="modal-footer">
        <button type="button" class="btn btn-light" data-bs-dismiss="modal">${I18n('close')}</button>
      </div>`;
    return `
      <div class="modal fade" id="${this.modalId}" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1" 
        data-bs-keyboard="false" data-bs-backdrop="static">
        <div class="modal-dialog ${this.sizeValue} modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header ${title ? '' : 'd-none'}">
              <h5 class="modal-title">${title}</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="position-absolute end-0 me-2 mt-2 ${title ? 'd-none' : ''}" style="z-index: 2">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body" id="${this.modalId}-body">
              ${content}
            </div>
            ${this.footerCloseValue ? closeTpl : ''}
          </div>
        </div>
      </div>
    `;
  }

  calcContent() {
    if (this.selfModalValue) return '';
    if (this.bodyValue) return this.bodyValue;
    if (this.targetValue) return this.targetContent();
    if (this.element.tagName === 'A') return '';

    return '---Unsupported modal--';
  }

  targetContent() {
    if (this.targetValue === ':next') return this.element.nextElementSibling.innerHTML;

    return document.body.querySelector(this.targetValue).innerHTML;
  }

  buildModal() {
    this.modalId = `modal_${modalsCounter}`;
    this.modalContainer().insertAdjacentHTML('beforeend', this.tpl(this.calcContent()));
    modalsCounter += 1;
    setTimeout(this.showModal.bind(this), 2);
  }

  showModal() {
    this.modalPanel = document.getElementById(this.modalId) as HTMLDivElement;
    const modalPanel = this.modalPanel;
    const modal = new Modal(modalPanel);
    if (document.body.querySelectorAll('.modal.show').length >= 1) { // overlap the previous modal
      this.modalPanel.style.background = 'rgba(0, 0, 0, 0.7)';
    }
    modal.show();
    this.modalPanel.addEventListener('close-modal', () => modal.hide());
    this.modalPanel.addEventListener('hidden.bs.modal', (ev) => {
      ev.stopPropagation();
      modalPanel.remove();
    });
  }

  modalContainer() {
    const closest = this.element.tagName === 'A' ? 'turbo-frame' : 'body';
    let target = this.element.closest(closest) || document.body;
    // 'data-modal-force-turboframe': true allows to stay modal inside turbo frame and not in body
    if (target.closest('.modal') && !this.element.getAttribute('data-modal-force-turboframe')) target = document.body;

    return target;
  }
}
