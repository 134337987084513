import { Controller } from '@hotwired/stimulus';
import Choices from 'choices.js';
import { concatUrlParams, debounce } from '../../helpers/misc';
import I18n from '../../helpers/translations';

// Sample: = select_tag 'q[speciality_id]', [], include_blank: t('common.select_all_option'),
//              'data-controller': 'form-autocomplete2'
//              'data-select2-preload': 'true' // will load options when dropdown is opened
//              'data-select2-search-url': '/search/query=...'
//              'data-select2-dependant': { selector: '#...', attr: 'state' }
export default class extends Controller {
  declare element: HTMLSelectElement;
  declare preloadOptions: boolean;
  declare searchUrl:string;
  declare choice:any;
  declare dependant: { selector: string, attr: string};
  declare defaultOption: string;
  declare preloaded: boolean;

  initialize() {
    this.defaultOption = this.element.querySelector('option[value=""]')?.textContent || '';
    this.dependant = JSON.parse(this.element.getAttribute('data-select2-dependant') || '{}');
    this.searchUrl = this.element.getAttribute('data-select2-search-url');
    this.preloadOptions = !!this.element.getAttribute('data-select2-preload');
    this.choice = new Choices(this.element, {
      position: 'bottom',
      classNames: {
        containerOuter: `choices ${this.element.getAttribute('class')}`,
        noChoices: 'd-none',
      },
      placeholderValue: this.element.getAttribute('data-placeholder') || I18n('type_here'),
      searchPlaceholderValue: this.element.getAttribute('data-placeholder') || I18n('type_here'),
      removeItemButton: this.element.multiple,
      itemSelectText: '',
      allowHTML: true,
      shouldSort: false, // if true ajax result "--all--" is moved to the end
      noResultsText: I18n('no_results_found'),
    });

    if (this.searchUrl) {
      if (this.preloadOptions) {
        this.element.addEventListener('showDropdown', () => {
          if (this.preloaded) return;
          this.preloaded = true;
          this.loadItems('');
        });
      } else this.attachFilter();
    }
    if (this.dependant.selector) {
      const depElement = document.querySelector(this.dependant.selector);
      if (depElement) depElement.addEventListener('change', () => this.loadItems(''));
    }
  }

  attachFilter() {
    const that = this;
    this.element.addEventListener('search', debounce(async (event) => {
      if (event.detail.value && event.detail.value.length >= 4) {
        await that.loadItems(event.detail.value);
      }
    }, 150));

    this.element.addEventListener('devSearch', this.onDevSearch.bind(this));
  }

  async loadItems(query) {
    let extraQuery = '';
    if (this.dependant.selector) {
      const depElement = document.querySelector(this.dependant.selector);
      if (depElement) extraQuery = `&${this.dependant.attr}=${depElement.value}`;
    }
    const data = await fetch(concatUrlParams(this.searchUrl, `query=${query}&${extraQuery}`))
      .then((response) => response.json());
    if (this.defaultOption) {
      this.choice.setChoices([{ value: '', label: this.defaultOption }], 'value', 'label', true);
    }
    this.choice.setChoices(data, 'value', 'label', false);
  }

  onDevSearch(event) {
    this.choice.input.isFocussed = true;
    this.element.click();
    this.choice.input.element.value = event.detail.value;
    this.choice.input.element.dispatchEvent(new Event('keyup'));
    setTimeout(() => this.pickResult(), 100);
  }

  pickResult() {
    const option = this.choice.choiceList.element.querySelector('.choices__item--selectable');
    if (option) option.dispatchEvent(new Event('mousedown'));
    else setTimeout(() => this.pickResult(), 100);
  }
}
