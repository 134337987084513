import { renderFlash } from '../helpers/flash';

export const requestHeaders = () => {
  const meta1 = document.querySelector('meta[name="csrf-param"]');
  const meta2 = document.querySelector('meta[name="csrf-token"]');
  const res = { 'No-Layout': 'true' };
  res[meta1?.content || 'na'] = meta2?.content;
  res['X-CSRF-Token'] = meta2?.content;
  return res;
};

const reloadTurboFrames = (frames: string[]) => {
  frames.forEach((frameSelector) => {
    const frame = document.body.querySelector<HTMLIFrameElement>(frameSelector);
    if (!frame) return;

    const { src } = frame;
    frame.src = null;
    frame.src = src;
  });
};

export const closeActiveModal = () => {
  const modal = Array.from(document.body.querySelectorAll('.modal.show')).pop();
  if (modal) modal.dispatchEvent(new CustomEvent('close-modal'));
};

const renderRequestError = async (response) => {
  if (response.url.includes('silence_ajax_error=true')) return;
  if (response.headers.get('content-type').includes('json')) {
    const json = await response.json();
    if (json.error) renderFlash(json.error);
    else console.error(json);
    return;
  }

  const result = await response.clone().text();
  let body = '';
  if (response.status === 500) {
    body = response.statusText;
  } else {
    const key = '<body class="rails-default-error-page">';
    body = result.includes(key) ? result.split(key)[1] : result.split('<body>')[1];
    body = body.split('</body>')[0];
  }
  const tpl = `
   <div data-controller="modal2"
   data-modal2-size-value="modal-lg text-danger" 
   data-modal2-self-modal-value="true">${body}</div>`;
  document.body.insertAdjacentHTML('beforeend', tpl);
};

let loadingCounter = 0;
let loadingTimer;
const hideLoading = () => {
  loadingCounter -= 1;
  if (loadingCounter <= 0) document.getElementById('main-progress-bar').classList.add('invisible');
};

const showLoading = () => {
  loadingCounter += 1;
  document.getElementById('main-progress-bar').classList.remove('invisible');
  if (loadingTimer) clearTimeout(loadingTimer);
  loadingTimer = setTimeout(() => { // when timeout or request was cancelled
    loadingCounter = 0;
    hideLoading();
  }, 20000);
};

const syncFrameParamsToUrl = (frameUrl:string, params:string) => {
  const href = new URL(window.location.href);
  const parsedFrameUrl = (frameUrl.startsWith('/') ? 'http://local/' : '') + frameUrl;
  const frameParams = new URL(parsedFrameUrl).searchParams;
  const urlParams = href.searchParams;
  params.split(',').forEach((key) => {
    if (frameParams.get(key)) urlParams.set(key, frameParams.get(key));
  });
  history.replaceState({}, '', href.toString());
};

document.addEventListener('turbo:click', (e) => {
  // Allows to access to the source link before fetching turbo request
  //  (as of 2023 fetch-request does not offer source link)
  window.lastTurboLink = null;
  if (e.target.tagName === 'A' && e.target.getAttribute('data-turbo-frame-id')) window.lastTurboLink = e.target;
});

// add request header to identify turbo requests aside from turbo-frame-requests
document.addEventListener('turbo:before-fetch-request', (e) => {
  const target = (window.lastTurboLink || e.target) as HTMLElement;
  window.lastTurboLink = null;

  // Mark all turbo requests as turbo-request
  e.detail.fetchOptions.headers['Turbo-Request'] = true;

  // provide Turbo-Frame-Referer url used for redirections on backend
  const frame = target.tagName === 'TURBO-FRAME' ? target : target.closest('turbo-frame');
  const frameSrc = frame?.getAttribute('src');
  if (frameSrc) e.detail.fetchOptions.headers['Turbo-Frame-Referer'] = frameSrc;

  // Allow forms to pass custom turbo-frame (as of Febr 2023, frames for FORMs are disabled)
  const forcedTurboID = target.getAttribute('data-turbo-frame-id');
  if (forcedTurboID) e.detail.fetchOptions.headers['Turbo-Frame'] = forcedTurboID;
  const turboAction = target.getAttribute('data-turbo-response-action');
  if (turboAction) e.detail.fetchOptions.headers['Turbo-Response-Action'] = turboAction;
});

// parse request response, including server errors
document.addEventListener('turbo:before-fetch-response', (e) => {
  const target = e.target as HTMLElement;

  // Keep synced full turbo-frame url to the browser url
  if (target.getAttribute('data-turbo-response-sync-url'))
    history.replaceState({}, '', e.detail.fetchResponse.response.url);
  // Keep synced full turbo-frame url GET params to the browser url
  if (target.getAttribute('data-turbo-response-sync-params-url'))
    history.replaceState({}, '', `${window.location.href.split("?")[0]}?${e.detail.fetchResponse.response.url.split('?')[1]}`);

  // Keep synced some frame params to the browser url
  // Sample: <turbo-frame src="a.com?d_id=10" data-turbo-response-sync-url-params="d_id,date"
  const urlSyncParams = target.getAttribute('data-turbo-response-sync-url-params');
  if (urlSyncParams) syncFrameParamsToUrl(target.getAttribute('src'), urlSyncParams);

  const { response } = e.detail.fetchResponse;
  if (response.status === 200) { // TODO: autofocus modal forms
    // allow to auto close active modal
    const closeModal = target.getAttribute('data-turbo-request-close-modal');
    if (closeModal) closeActiveModal();

    // allow auto-reload turbo-frames
    const frames = target.getAttribute('data-turbo-request-reload-frame');
    if (frames) reloadTurboFrames(frames.split(','));

    // allow auto-reload current page
    if (target.getAttribute('data-turbo-request-reload-page')) window.location.reload();

    // allow auto-reload parent (frame or window)
    const reloadParent = target.getAttribute('data-turbo-request-reload-parent');
    if (reloadParent) {
      const frame = target.closest('turbo-frame');
      frame ? reloadTurboFrames([frame.id]) : window.location.reload();
    }
  } else {
    const isTurboResponse = response.headers.get('content-type').includes('text/vnd.turbo-stream.html');
    if (!isTurboResponse) {
      e.preventDefault();
      renderRequestError(response);
    }
  }

  const parentTarget = target.parentElement;
  if (parentTarget && parentTarget.classList.contains('modal-body')) { // Autofocus modal forms
    setTimeout(() => {
      parentTarget.querySelector('input:not([type="hidden"]), select, textarea')?.focus();
    }, 300);
  }

  // iframe auto-resize
  if(window !== window.parent) setTimeout(() => window.pymChild.sendHeight(), 200);
});

// subscribe to request progress events
document.addEventListener('turbo:before-fetch-request', showLoading);
document.addEventListener('turbo:before-fetch-response', hideLoading);

export const ajaxRequest = async (path, attrs: object): Promise<Response> => {
  const { data = {}, method = 'GET', headers = {}, loadingState = true } = attrs;
  const reqData = {
    method,
    headers: { ...requestHeaders(), ...headers },
  };
  if (method !== 'GET') reqData.body = data;

  return new Promise(async (success, reject) => {
    if (loadingState) showLoading();
    const response = await fetch(path, reqData);
    if (loadingState) hideLoading();
    if ([200, 302].includes(response.status)) success(response);
    else {
      await renderRequestError(response);
      reject(response);
    }
  });
};

// Fix browser back navigation issue (does not reload when back btn)
if (window.history.state && window.history.state.turbo) {
  window.addEventListener('popstate', () => { window.location.reload(); });
}
