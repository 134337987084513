export const previousSiblings = (elem: HTMLElement, untilEle?: HTMLElement) => {
  let siblings = [];
  while (elem != untilEle) {
    siblings.push(elem);
    elem = elem.previousElementSibling as HTMLElement;
  }
  return siblings;
}

export const nextSiblings = (elem: HTMLElement, untilEle?: HTMLElement) => {
  let siblings = [];
  while (elem != untilEle) {
    siblings.push(elem);
    elem = elem.nextElementSibling as HTMLElement;
  }
  return siblings;
};

export const addClasses = (ele: HTMLElement, classes: string[]) => {
  classes.forEach((cls) => ele.classList.add(cls));
};

export const wrapEle = (el: HTMLElement, classes: string[] = []) => {
  const wrapper = document.createElement('div');
  el.parentNode.insertBefore(wrapper, el);
  wrapper.appendChild(el);
  addClasses(wrapper, classes);
  return wrapper;
};

export const toggleClass = (ele: HTMLElement|Element, klass: string) => {
  if (ele.classList.contains(klass)) ele.classList.remove(klass);
  else ele.classList.add(klass);
};

export const switchClass = (ele: HTMLElement|Element, klass1: string, klass2: string) => {
  ele.classList.add(klass1);
  ele.classList.remove(klass2);
};
