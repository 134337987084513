import { Controller } from '@hotwired/stimulus';

type IOffice = {
  id: number
  info_url: string
  latitude: string
  longitude: string
}

export default class extends Controller {
  static values = { api: String };
  static targets = ['searchBox'];
  declare apiValue: string;
  declare searchBoxTarget: HTMLInputElement;

  declare map: any;
  declare autocomplete: any;
  declare infoWindow: any;
  declare distance: number;
  declare markers: any[];
  declare infoLink: HTMLAnchorElement;

	initialize() {
		this.markers = [];
		this.distance = 5;
    this.initMap();
    this.buildAutoComplete();
    this.buildInfoWindow();
    this.bindCenterChange();
    this.centerCurrentLocation();
    this.addInfoLink();
  }


  initMap() {
    this.element.insertAdjacentHTML('beforeend', '<div style="height: 500px; width: 100%" id="map"></div>');
    this.map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        center: { lat: -17.382861022536243, lng: -66.15466732124024 },
        zoom: 13,
        mapTypeControl: false,
        styles: [{ featureType: "poi.business", stylers: [{ visibility: "off" }] }],
      }
    );
  }

  buildAutoComplete() {
    const options = {
      fields: ["geometry"],
      strictBounds: false,
      types: ["establishment"],
    };

    this.searchBoxTarget.style.cssText = 'position: absolute; z-index: 2; margin-top: 10px;';
    this.autocomplete = new google.maps.places.Autocomplete(this.searchBoxTarget, options);
    this.autocomplete.bindTo("bounds", this.map);
    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete.getPlace();
      this.map.setCenter(place.geometry.location);
    });
  }

  buildInfoWindow() {
    this.infoWindow = new google.maps.InfoWindow();
  }

  loadMarkers() {
    const res = fetch(this.apiValue);
    res.then(response => response.json())
       .then((data: IOffice[]) => {
				 data.forEach(office => this.addMarker(office));
				 this.resetMarkers();
			 });
  }

  resetMarkers() {
		const visibleMarkers = this.markers.filter(marker =>
			marker.getVisible() && this.map.getBounds()?.contains(marker.getPosition())
		);
		this.markers.forEach((marker) => { visibleMarkers.includes(marker) ? marker.setMap(this.map) : marker.setMap(null) });
	}

  addMarker(office: IOffice) {
    const marker = new google.maps.Marker({
      map: this.map,
      position: { lat: parseFloat(office.latitude), lng: parseFloat(office.longitude) }
    });
    marker.addListener("click", () => {
			this.infoLink.href = office.info_url;
			this.infoLink.click();

      // const tpl = `<turbo-frame loading="lazy" id="turbo_feedback" src="${office.info_url}"></turbo-frame>`;
    	// this.infoWindow.close();
      // this.infoWindow.setContent(tpl);
      // this.infoWindow.open(marker.getMap(), marker);
    });
    this.markers.push(marker);
  }

	bindCenterChange() {
		this.map.addListener("dragend", this.resetMarkers.bind(this));
		this.map.addListener("center_changed", this.resetMarkers.bind(this));
		this.map.addListener("zoom_changed", this.resetMarkers.bind(this));
	}

	addInfoLink() {
		this.infoLink = document.createElement('a') as HTMLAnchorElement;
		this.infoLink.classList.add('d-none');
		this.infoLink.id = 'map-doctor-profile-lnk';
		this.infoLink.setAttribute('data-controller', 'modal2');
		this.infoLink.setAttribute('data-modal2-size-value', 'modal-xl');
		document.body.insertAdjacentElement('beforeend', this.infoLink);
	}

  centerCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
					this.loadMarkers();
        	this.map.setCenter({ lat: position.coords.latitude, lng: position.coords.longitude });
        },
        (e) => {
          console.error('not possible to detect your location:', e);
          this.loadMarkers();
        },
      );
    } else {
      console.error('not possible to detect your location');
      this.loadMarkers();
    }
  }
}
