// TODO: take into account if panel is visible or not
import I18n from './translations';

export const isInViewport = (element, padding = 100) => {
  const pos = element.getBoundingClientRect();
  return !(pos.top - padding > window.innerHeight || pos.bottom < 0);
};

export const concatUrlParams = (url: string, params: string) => `${url}${url.includes('?') ? '&' : '?'}${params}`;
export const debounce = (func, wait = 250) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const secondsToString = (seconds) => {
  const numyears = Math.floor(seconds / 31536000);
  const numdays = Math.floor((seconds % 31536000) / 86400);
  const numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
  const numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
  // const numseconds = (((seconds % 31536000) % 86400) % 3600) % 60;
  const res = [];
  if (numyears > 0) res.push(`${numyears} ${I18n('years')}`);
  if (numdays > 0) res.push(`${numdays} ${I18n('days')}`);
  if (numhours > 0) res.push(`${numhours} ${I18n('hours')}`);
  if (numminutes > 0) res.push(`${numminutes} ${I18n('minutes')}`);
  return res.length === 0 ? 0 : res.join(', ');
};

export const loadJs = (url, callback = null) => {
  const script = document.createElement('script');
  script.onload = function () {
    if (callback) callback();
  };
  script.src = url;
  document.head.appendChild(script);
};

// Copied from gist
export const objectToQueryString = function (a) {
  var prefix, s, add, name, r20, output;
  s = [];
  r20 = /%20/g;
  add = function (key, value) {
    // If value is a function, invoke it and return its value
    value = ( typeof value == 'function' ) ? value() : ( value == null ? "" : value );
    s[ s.length ] = encodeURIComponent(key) + "=" + encodeURIComponent(value);
  };
  if (a instanceof Array) {
    for (name in a) {
      add(name, a[name]);
    }
  } else {
    for (prefix in a) {
      buildParams(prefix, a[ prefix ], add);
    }
  }
  output = s.join("&").replace(r20, "+");
  return output;
};

function buildParams(prefix, obj, add) {
  var name, i, l, rbracket;
  rbracket = /\[\]$/;
  if (obj instanceof Array) {
    for (i = 0, l = obj.length; i < l; i++) {
      if (rbracket.test(prefix)) {
        add(prefix, obj[i]);
      } else {
        buildParams(prefix + "[" + ( typeof obj[i] === "object" ? i : "" ) + "]", obj[i], add);
      }
    }
  } else if (typeof obj == "object") {
    // Serialize object item.
    for (name in obj) {
      buildParams(prefix + "[" + name + "]", obj[ name ], add);
    }
  } else {
    // Serialize scalar item.
    add(prefix, obj);
  }
}
